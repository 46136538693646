import React from 'react';
import { Route } from 'react-router-dom';

const refresh = ({ history, location, match }) => {
    history.replace({
        ...location,
        pathname: location.pathname.slice(match.path.length),
    });
    return null;
};

/**
 * Route which refreshes the page by remounting each component.
 */
export const RefreshRoute = ({ path = '/', ...rest }) => <Route {...rest} path={path} component={refresh} />;
