import { DefaultButton } from 'office-ui-fabric-react';
import React from 'react';
import I18n from '../../../helper/Localization';

/**
 * The button to open the end customer dialog.
 */
export const OpenEndCustomerDialogButton = (props) => {
    /**
     * Styles of the button.
     */
    const buttonStyles = {
        root: {
            minWidth: '100%',
        },
    };

    return <DefaultButton styles={buttonStyles} text={I18n.get().t('EndCustomerDialog_OpenButton_Text')} onClick={props.callback} />;
};
