import React from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import styled from 'styled-components/macro';
import I18n from '../helper/Localization';
import { Colors } from '../styles/Globals';

const OfflineSwitchContainer = styled.div`
    display: flex;
    align-items: center;
    min-height: max-content;
    min-width: max-content;
`;

const StatusText = styled.div`
    font-size: 12px;
    margin-left: 15px;
    color: ${(props) => props.isDisabled && Colors.inactiveGray};
`;

/**
 * The switch component to toggle the offline store state.
 */
export const OfflineSwitch = (props) => {
    /**
     * Retrieve the store state of the offline mode.
     */
    const isOfflineModeEnabled = useStoreState((state) => state.ui.isOfflineModeEnabled);

    /**
     * Retrieve the action to update the store offline mode state.
     */
    const updateIsOfflineModeEnabled = useStoreActions((actions) => actions.ui.updateIsOfflineModeEnabled);

    /**
     * Styles of the offline switch.
     */
    const switchStyles = {
        root: {
            margin: 0,
        },
    };

    /**
     * Callback to toggle the store state of the offline mode.
     * @param {*} event The click event.
     * @param {*} checked The checked status of the toggle component.
     */
    const toggleOfflineMode = (event, checked) => {
        updateIsOfflineModeEnabled(checked);
    };

    return (
        <OfflineSwitchContainer>
            <Toggle disabled={props.disabled} checked={isOfflineModeEnabled} styles={switchStyles} onChange={toggleOfflineMode} />
            <StatusText isDisabled={props.disabled}>{isOfflineModeEnabled ? I18n.get().t('OfflineSwitch_Active') : I18n.get().t('OfflineSwitch_Inactive')}</StatusText>
        </OfflineSwitchContainer>
    );
};
