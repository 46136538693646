import React, { useEffect, useState } from 'react';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import styled from 'styled-components/macro';
import I18n from '../../../helper/Localization';
import OrderTestList from './OrderTestList';
import { BusySpinner } from '../../busyIndicators/BusySpinner';
import { fetchApiObject, putToApi } from '../../../helper/ApiHelper';
import { Colors } from '../../../styles/Globals';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { IconButton } from 'office-ui-fabric-react';
import { getTheme } from 'office-ui-fabric-react';
import { Icon } from '@fluentui/react/lib/Icon';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { useStoreState } from 'easy-peasy';
import { isUserAccountManager, isUserAdministrator } from '../../../helper/RoleHelper';

const Label = styled.div`
    text-align: left;
    margin-bottom: 5px;
    font: Bold 10px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: ${Colors.inactiveGray};
    opacity: 1;
`;

const OrderTestListWrapper = styled.div`
    display: flex;
    overflow: hidden;
`;

const Data = styled.div`
    text-align: left;
    margin-bottom: 10px;
    font: 400 14px/15px NeueHaasGroteskText W01;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

const UserDataContainer = styled.div`
    margin-bottom: 30px;
`;

const LabelContainer = styled.div`
    margin-right: 10px;
`;

const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const UserData = styled.div`
    border: 1px solid var(--unnamed-color-f0f0f0);
    padding: 20px 20px 10px 20px;
    background-color: ${Colors.backgroundGray};
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    height: fit-content;
    margin: 0px;
`;

const Text = styled.p`
    color: var(--unnamed-color-000000);
    text-align: left;
    margin-bottom: 10px;
    font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    height: calc(100vh - 130px);
    overflow-y: hidden;
    @media only screen and (max-width: 1400px) {
        height: calc(100vh - 155px);
    }
`;

const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px;
`;

const MessageWrapper = styled.div`
    display: flex;
    flex: 1;
    visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
`;

/**
 * Styles of the input fields.
 */
const textFieldStyles = {
    root: {
        fontSize: 10,
    },
    field: {
        height: 23,
        paddingLeft: 20,
    },
    fieldGroup: {
        height: 24,
        border: `0.5px solid ${Colors.borderGray};`,
    },
    errorMessage: {
        fontSize: 10,
    },
};

const ActionWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const EditWrapper = styled.div`
    display: flex;
    flex: 1;
    gap: 10px;
`;


/**
 * Dialog displaying detailed information about an order.
 */
const OrderDetailDialog = (props) => {

    const theme = getTheme();


    const editButtonControls = {
        root: {
            backgroundColor: 'gray',
            color: theme.palette.white,
            borderRadius: '2px',
            height: 23,
            minWidth: 30,
            padding: 0,
            margin: '0',
        },
        rootHovered: {
            backgroundColor: 'none',
            border: 'unset',
        },
        rootPressed: {
            backgroundColor: 'none',
            border: 'unset',
        },
        rootFocused: {
            border: 'unset',
            outline: 0,
        },
    };

    const editIcon = <Icon styles={{ root: { marginRight: 10, color: Colors.darkGreen, '&:hover': { color: theme.palette.themePrimary } } }} iconName="Edit" />;

    /**
     * State of the test results that belong to the order position.
     */
    const [testResults, setTestResults] = useState([]);
    const [editWearerMode, setEditWearerMode] = useState(false);
    const [editEndCustomerNumberMode, setEditEndCustomerNumberMode] = useState(false)
    const [wearer, setWearer] = useState('');
    const [endCustomerNumber, setEndCustomerNumber] = useState('');
    const [isBusyWearerUpdate, SetIsBusyWearerUpdate] = useState(false);
    const [isBusyEndCustomerNumberUpdate, SetIsBusyEndCustomerNumberUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const userRoles = useStoreState((state) => state.user.roles);
    const isAccountManager = isUserAccountManager(userRoles);
    const isAdmin = isUserAdministrator(userRoles);

    useEffect(() => {
        if (props.selectedOrderPosition && props.hidden === false) {
            const fetchData = async () => {
                const result = await fetchApiObject(`v1/TestResult/OrderPosition/${props.selectedOrderPosition.id}`);
                setTestResults(result);
            };
            fetchData();
        }
    }, [props.selectedOrderPosition, props.hidden]);

    const cancleButtonStyles = {
        root: {
            fontSize: '10px',
        },
    };

    /**
     * Close the dialog.
     */
    const closeDialog = () => {
        setTestResults([]);
        props.toggle(true);
    };

    const updateEndCustomerNumber = async () => {
        setSuccessMessage('');
        setErrorMessage('');
        SetIsBusyEndCustomerNumberUpdate(true);

        try {
            await putToApi(`v1.0/Order/${props.selectedOrderPosition.orderId}/endcustomer-number`, endCustomerNumber);
            setSuccessMessage(I18n.get().t('DetailsOrderDialog_SuccessMessage'));
            const updatedOrderPostion = { ...props.selectedOrderPosition, endCustomerNumber: endCustomerNumber };
            props.setSelectedOrderPosition(updatedOrderPostion);
            props.updateOrderPositionsAtCurrentIndex(updatedOrderPostion);
            setEditEndCustomerNumberMode(false);
            setTimeout(() => setSuccessMessage(''), 2000);
        } catch (error) {
            console.error(error);
            setErrorMessage(I18n.get().t('DetailsOrderDialog_ErrorMessage'));
            setTimeout(() => setSuccessMessage(''), 2000);
        } finally {
            SetIsBusyEndCustomerNumberUpdate(false);
        }
    };

    const updateWearer = async () => {
        setSuccessMessage('');
        setErrorMessage('');
        SetIsBusyWearerUpdate(true);

        try {
            await putToApi(`v1.0/OrderPosition/${props.selectedOrderPosition.id}/wearer`, wearer);
            setSuccessMessage(I18n.get().t('DetailsOrderDialog_SuccessMessage'));
            const updatedOrderPostion = { ...props.selectedOrderPosition, wearer: wearer };
            props.setSelectedOrderPosition(updatedOrderPostion);
            props.updateOrderPositionsAtCurrentIndex(updatedOrderPostion);
            setEditWearerMode(false);
            setTimeout(() => setSuccessMessage(''), 2000);
        } catch (error) {
            console.error(error);
            setErrorMessage(I18n.get().t('DetailsOrderDialog_ErrorMessage'));
            setTimeout(() => setSuccessMessage(''), 2000);
        } finally {
            SetIsBusyWearerUpdate(false);
        }
    };

    /**
     * Body of the panel
     */
    const onRenderBody = () => (
        <PanelBody>
            <UserDataContainer>
                <Text>{I18n.get().t('OrderDetail_UserData_Title')}</Text>
                <UserData>
                    <LabelContainer>
                        <Label>{`${I18n.get().t('OrderDetail_UserData_Ordernumber_Label')}:`}</Label>
                        <DataContainer>
                            <Data>{props.selectedOrderPosition.orderDeliveryInvoiceNumber}</Data>
                        </DataContainer>
                        <Label>{`${I18n.get().t('OrderDetail_UserData_Position_Label')}:`}</Label>
                        <DataContainer>
                            <Data>{props.selectedOrderPosition.position}</Data>
                        </DataContainer>
                        <Label>{`${I18n.get().t('OrderDetail_UserData_Wearer_Label')}:`}</Label>
                        <DataContainer>
                            {!editWearerMode && <EditWrapper>
                                <Data>{props.selectedOrderPosition.wearer}</Data>
                                {(isAccountManager || isAdmin) &&
                                    <Icon styles={{ root: { color: theme.palette.themePrimary, marginTop: -2, cursor: 'pointer', '&:hover': { color: theme.palette.themePrimary } } }} iconName="Edit" onClick={() => setEditWearerMode(true)} />}

                            </EditWrapper>}
                            {(isAccountManager || isAdmin) && editWearerMode && <ActionWrapper>
                                <TextField
                                    maxLength={40}
                                    styles={textFieldStyles}
                                    defaultValue={props.selectedOrderPosition.wearer}
                                    placeholder=""
                                    onChange={(event, newValue) => {
                                        if (newValue || newValue === '') {
                                            setWearer(newValue);
                                        }
                                    }}
                                />
                                <IconButton styles={editButtonControls} iconProps={{ iconName: 'Accept' }} onClick={() => updateWearer()}>
                                </IconButton>
                                <IconButton styles={editButtonControls} iconProps={{ iconName: 'ChromeClose' }} onClick={() => setEditWearerMode(false)}>
                                </IconButton>
                                <BusySpinner isBusy={isBusyWearerUpdate} />
                            </ActionWrapper>}
                        </DataContainer>
                        <Label>{`${I18n.get().t('OrderDetail_UserData_MaterialName_Label')}:`}</Label>
                        <DataContainer>
                            <Data>{props.selectedOrderPosition.materialName}</Data>
                        </DataContainer>
                        <Label>{`${I18n.get().t('OrderDetail_UserData_MaterialNumber_Label')}:`}</Label>
                        <DataContainer>
                            <Data>{props.selectedOrderPosition.materialNumber}</Data>
                        </DataContainer>
                        <Label>{`${I18n.get().t('OrderDetail_UserData_CustomerNumber_Label')}:`}</Label>
                        <DataContainer>              
                            {!editEndCustomerNumberMode && <EditWrapper>
                                <Data>{props.selectedOrderPosition.endCustomerNumber}</Data>
                                {(isAccountManager || isAdmin) && <Icon styles={{ root: { color: theme.palette.themePrimary, marginTop: -2, cursor: 'pointer', '&:hover': { color: theme.palette.themePrimary } } }} iconName="Edit" onClick={() => setEditEndCustomerNumberMode(true)} /> }

                            </EditWrapper>}
                            {(isAccountManager || isAdmin) &&  editEndCustomerNumberMode && <ActionWrapper>
                                <TextField
                                    maxLength={40}
                                    styles={textFieldStyles}
                                    defaultValue={props.selectedOrderPosition.endCustomerNumber}
                                    placeholder=""
                                    onChange={(event, newValue) => {
                                        if (newValue || newValue === '') {
                                            setEndCustomerNumber(newValue);
                                        }
                                    }}
                                />
                                <IconButton styles={editButtonControls} iconProps={{ iconName: 'Accept' }} onClick={() => updateEndCustomerNumber()}>
                                </IconButton>
                                <IconButton styles={editButtonControls} iconProps={{ iconName: 'ChromeClose' }} onClick={() => setEditEndCustomerNumberMode(false)} >
                                </IconButton>
                                <BusySpinner isBusy={isBusyEndCustomerNumberUpdate} />
                            </ActionWrapper>}
                        </DataContainer>
                    </LabelContainer>
                </UserData>
            </UserDataContainer>
            <OrderTestListWrapper>
                <OrderTestList testResults={testResults} />
            </OrderTestListWrapper>
        </PanelBody>
    );

    /**
     * Footer content of the panel.
     */
    const onRenderFooterContent = () => (
        <PanelFooter>
            <MessageWrapper hidden={errorMessage === '' && successMessage === ''}>
                <MessageBar isMultiline={false} messageBarType={successMessage !== '' ? MessageBarType.success : MessageBarType.error}>
                    {successMessage !== '' ? successMessage : errorMessage}
                </MessageBar>
            </MessageWrapper>
            <PrimaryButton styles={cancleButtonStyles} onClick={closeDialog} text={I18n.get().t('OrderDetail_Close_Button')} />
        </PanelFooter>
    );

    return (
        <Panel
            allowTouchBodyScroll
            isLightDismiss
            isOpen={props.hidden === false}
            headerText={I18n.get().t('OrderDetail_Title')}
            isFooterAtBottom={true}
            onDismiss={closeDialog}
            type={PanelType.extraLarge}
            closeButtonAriaLabel="Schließen"
            onRenderBody={onRenderBody}
            onRenderFooter={onRenderFooterContent}
        />
    );
};

export default OrderDetailDialog;
