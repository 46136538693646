import { getToken } from './AuthHelper';
import { _config } from '../OnlineApp';
import I18n from './Localization';

/**
 * Custom error class for HTTP errors.
 */
export class HttpError extends Error {
    constructor(status, message) {
        super(message);
        this.status = status;
        console.error(`HTTP Request (${status}): "${message}"`);
    }
    status = -1;
}

/**
 * Fetches the API for a simple string response.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
export const fetchApiString = (relativePath, abortSignal) => {
    return fetchApi(relativePath, 'GET', null, abortSignal).then((response) => response.text());
};

/**
 * Deletes an object from the api.
 *
 * @template T
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
export const deleteFromApi = async (relativePath, abortSignal) => {
    return fetchApi(relativePath, 'DELETE', undefined, abortSignal).then(async (response) => {
        if (response.ok) {
            return response.json();
        }
        throw new HttpError(response.status, response.text());
    });
};

/**
 * Fetches the API for a JSON object.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
export const fetchApiObject = (relativePath, abortSignal) => {
    return fetchApi(relativePath, 'GET', null, abortSignal).then((response) => response.json());
};

/**
 * Posts a request to the backend API.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
export const postToApi = (relativePath, body, abortSignal) => {
    return fetchApi(relativePath, 'POST', JSON.stringify(body), abortSignal).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw new HttpError(response.status, response.text());
    });
};

/**
 * Posts a put request to the backend API.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
export const putToApi = (relativePath, body, abortSignal) => {
    return fetchApi(relativePath, 'PUT', JSON.stringify(body), abortSignal).then((response) => {
        if (response.ok) {
            return response.json();
        }
        throw new HttpError(response.status, response.text());
    });
};
/**
 * Posts a request to the backend API.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {any} body The object to put in the body of the request
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
export const postToApiBlob = async (relativePath, body, fileName, abortSignal) => {
    return fetchApi(relativePath, 'POST', body, abortSignal)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
};

/**
 * Calls the backend API.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {string} method The HTTP verb to use.
 * @param {any} body The object to put in the body of the request.
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
const fetchApi = async (relativePath, method, body, abortSignal) => {
    const apiConfig = (await _config).apiOptions;
    return fetchWithHeader(`${apiConfig.baseUrl}/${relativePath}`, method, [apiConfig.scope], body, abortSignal);
};

/**
 * Fetches the API with required default headers.
 * @param {string} relativePath The relative path to the endpoint to query.
 * @param {string} method The HTTP verb to use.
 * @param {string[]} scopes The scopes to request for authorization.
 * @param {any} body The object to put in the body of the request.
 * @param {signal} abortSignal An abort signal to cancel the request.
 */
const fetchWithHeader = async (url, method, scopes, body, abortSignal) => {
    const token = await getToken(scopes);
    return fetch(url, {
        method,
        withCredentials: true,
        headers: {
            'Time-Offset': new Date().getTimezoneOffset() * -1,
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept-Language': I18n.getInstance().i18nInstance?.language ? I18n.getInstance().i18nInstance.language : 'de-de',
        },
        signal: abortSignal,
        body,
    }).catch((error) => {
        throw error;
    });
};
