import React from 'react';
import I18n from '../../helper/Localization';
import styled from 'styled-components/macro';
import { ProgressItem } from './ProgressItem';
import UvexLogo from '../../assets/uvex.svg';
import { getTheme } from 'office-ui-fabric-react';
import { OfflineSwitch } from '../OfflineSwitch';
import { useStoreState } from 'easy-peasy';

const TestProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eee;
    background-color: #ffffff;
    width: 100%;
`;

const LogoContainer = styled.div`
    width: 100%;
    height: 50px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    border-bottom: 0.2px solid #c3c3c3;
`;

const OfflineSwitchWrapper = styled.div`
    margin-left: 20px;
`;

const Logo = styled.img`
    height: 20px;
`;

const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    border-bottom: 0.2px solid #c3c3c3;
    @media only screen and (max-width: 1400px) {
        height: 65px;
        font-size: 10px;
        align-items: baseline;
    }
`;

const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
`;

const Line = styled.div`
    width: ${(props) => props.lineWidth};
    height: 3px;
    background-color: ${(props) => props.progressColor};
`;

/**
 * component that indicates the current process of the hearing test
 */
export const TestProgressBar = (props) => {
    /**
     * Retrieve the store state of the offline mode.
     */
    const isOfflineModeEnabled = useStoreState((state) => state.ui.isOfflineModeEnabled);

    /**
     * Fluent ui theme.
     */
    const theme = getTheme();

    return (
        <TestProgressBarContainer>
            <LogoContainer>
                <Logo src={UvexLogo}></Logo>
                <OfflineSwitchWrapper>
                    <OfflineSwitch disabled={props.progress > 0} />
                </OfflineSwitchWrapper>
            </LogoContainer>
            <ProgressWrapper>
                <ProgressContainer>
                    <Line lineWidth="50%" progressColor={theme.palette.black} />
                    {isOfflineModeEnabled && (
                        <>
                            <ProgressItem
                                text={I18n.get().t('Test_Progress_KickOff')}
                                progressColor={props.progress >= -1 ? theme.palette.black : theme.palette.neutralTertiary}
                                checked={props.progress >= 0 ? true : false}
                            />
                            <Line lineWidth="100%" progressColor={theme.palette.black} />
                        </>
                    )}
                    <ProgressItem
                        text={I18n.get().t('Test_Progress_Intro')}
                        progressColor={props.progress >= 0 ? theme.palette.black : theme.palette.neutralTertiary}
                        checked={props.progress >= 1 ? true : false}
                    />
                    <Line lineWidth="100%" progressColor={props.progress >= 1 ? theme.palette.black : theme.palette.neutralTertiary} />
                    <ProgressItem
                        text={I18n.get().t('Test_Progress_Instructions')}
                        progressColor={props.progress >= 1 ? theme.palette.black : theme.palette.neutralTertiary}
                        checked={props.progress >= 2 ? true : false}
                    />
                    <Line lineWidth="100%" progressColor={props.progress >= 2 ? theme.palette.black : theme.palette.neutralTertiary} />
                    <ProgressItem
                        text={I18n.get().t('Test_Progress_WithoutProtection')}
                        progressColor={props.progress >= 2 ? theme.palette.black : theme.palette.neutralTertiary}
                        checked={props.progress >= 3 ? true : false}
                    />
                    <Line lineWidth="100%" progressColor={props.progress >= 3 ? theme.palette.black : theme.palette.neutralTertiary} />
                    <ProgressItem
                        text={I18n.get().t('Test_Progress_WithProtection')}
                        progressColor={props.progress >= 3 ? theme.palette.black : theme.palette.neutralTertiary}
                        checked={props.progress >= 4 ? true : false}
                    />
                    <Line lineWidth="100%" progressColor={props.progress >= 4 ? theme.palette.black : theme.palette.neutralTertiary} />
                    <ProgressItem
                        text={I18n.get().t('Test_Progress_Result')}
                        progressColor={props.progress >= 4 ? theme.palette.black : theme.palette.neutralTertiary}
                        checked={props.progress >= 5 ? true : false}
                    />
                    <Line lineWidth="50%" progressColor={props.progress >= 5 ? theme.palette.black : theme.palette.neutralTertiary} />
                </ProgressContainer>
            </ProgressWrapper>
        </TestProgressBarContainer>
    );
};
