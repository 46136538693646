import React, { useState } from 'react';
import styled from 'styled-components/macro';
import I18n from '../../helper/Localization';
import { getTheme } from 'office-ui-fabric-react';
import { Colors } from '../../styles/Globals';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { validateEmail } from '../../helper/ValidationHelper';

const OfflineIntroViewContainer = styled.div`
    display: flex;
    flex: 1;
`;

const DataInputWrapper = styled.div`
    display: flex;
    flex: 10;
    flex-direction: column;
    padding: 50px 60px 25px 60px;
    @media only screen and (max-width: 1800px) {
        padding: 15px 20px;
    }
`;

const TextfieldWrapper = styled.div`
    min-height: 85px;
`;

const TextareaWrapper = styled.div`
    margin: 20px 0;
    @media only screen and (max-width: 1800px) {
        margin: 10px 0;
    }
`;

const DataInputContainer = styled.div`
    max-width: 45%;
    flex-direction: column;
    @media only screen and (max-width: 1800px) {
        max-width: 70%;
    }
`;

const DataInputHeadline = styled.div`
    font-size: 40px;
    font-weight: bold;
    @media only screen and (max-width: 1800px) {
        font-size: 33px;
    }
`;

const DataInputSubHeadline = styled.div`
    font-size: 30px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1800px) {
        font-size: 23px;
        margin-bottom: 15px;
    }
`;

const DataInputText = styled.div`
    font-size: 14px;
    color: ${Colors.inactiveGray};
`;

const ExplanationContainer = styled.div`
    display: flex;
    flex: 9;
    padding: 50px 40px;
    flex-direction: column;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.fontColor};
    @media only screen and (max-width: 1800px) {
        padding: 25px 20px;
    }
`;

const ErrorBar = styled.div`
    width: 100%;
    margin-right: 20px;
    font-size: 12px;
    padding: 8px 12px;
    background-color: ${Colors.errorRedLight};
`;

const ExplanationHeadline = styled.div`
    font-size: 30px;
    margin-top: 49px;
    word-wrap: break-word;
    @media only screen and (max-width: 1800px) {
        font-size: 23px;
        margin-top: 39px;
    }
`;

const ExplanationText = styled.li`
    font-size: 14px;
    word-wrap: break-word;
    margin-top: 30px;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
`;

/**
 * The intro view for the hearing test when the user is in offline mode.
 */
export const OfflineIntroView = (props) => {
    const theme = getTheme();

    /**
     * State of the error message to display.
     */
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Styles of the continue button.
     */
    const continueButtonStyles = {
        root: {
            borderRadius: '5px',
            fontSize: '15px',
            border: 0,
            minWidth: '120px',
            height: '33px',
            boxShadow: '1px 2px 3px #00000079',
        },
        rootHovered: {
            background: theme.palette.themePrimary,
        },
        rootPressed: {
            background: theme.palette.themePrimary,
        },
        rootDisabled: {
            background: theme.palette.themePrimary,
        },
    };

    /**
     * Regex email validation.
     * Returns a error text to fluent ui.
     * @param value The data that requires validation.
     */
    const onGetErrorEmail = (value) => {
        return validateEmail(value) ? '' : I18n.get().t('Invite_User_InvalidEmail');
    };

    /**
     * Start the hearing test in offline mode after checking necessary informations.
     */
    const startOfflineTest = () => {
        if (!validateEmail(props.userMail) || props.notes === '') {
            setErrorMessage(I18n.get().t('OfflineMode_DataInput_ErrorMessage'));
            return;
        }
        setErrorMessage('');
        props.continue();
    };

    return (
        <OfflineIntroViewContainer>
            <DataInputWrapper>
                <DataInputContainer>
                    <DataInputHeadline>{I18n.get().t('OfflineMode_DataInput_Headline')}</DataInputHeadline>
                    <DataInputSubHeadline>{I18n.get().t('OfflineMode_DataInput_SubHeadline')}</DataInputSubHeadline>
                    <TextfieldWrapper>
                        <TextField
                            label={I18n.get().t('OfflineMode_DataInput_EmailLabel')}
                            value={props.userMail}
                            onGetErrorMessage={onGetErrorEmail}
                            onChange={(event, newValue) => {
                                if (newValue || newValue === '') {
                                    props.updateEmail(newValue);
                                }
                            }}
                        />
                    </TextfieldWrapper>
                    <DataInputText>{I18n.get().t('OfflineMode_DataInput_EmailTip')}</DataInputText>
                    <TextareaWrapper>
                        <TextField
                            label={I18n.get().t('OfflineMode_DataInput_TextLabel')}
                            multiline
                            value={props.notes}
                            resizable={false}
                            rows={window.innerWidth >= 1800 ? 7 : 4}
                            onChange={(event, newValue) => {
                                if (newValue || newValue === '') {
                                    props.updateNotes(newValue);
                                }
                            }}
                        />
                    </TextareaWrapper>
                    <DataInputText>{I18n.get().t('OfflineMode_DataInput_TextTip')}</DataInputText>
                </DataInputContainer>
                <ButtonContainer>
                    {errorMessage !== '' && <ErrorBar>{errorMessage}</ErrorBar>}
                    <PrimaryButton styles={continueButtonStyles} text={I18n.get().t('OfflineMode_DataInput_Continue')} onClick={startOfflineTest} />
                </ButtonContainer>
            </DataInputWrapper>
            <ExplanationContainer backgroundColor={theme.palette.black} fontColor={theme.palette.white}>
                <ExplanationHeadline>{I18n.get().t('OfflineMode_Tutorial_Headline')}</ExplanationHeadline>
                <ul>
                    <ExplanationText>{I18n.get().t('OfflineMode_Tutorial_TextOne')}</ExplanationText>
                    <ExplanationText>{I18n.get().t('OfflineMode_Tutorial_TextTwo')}</ExplanationText>
                    <ExplanationText>{I18n.get().t('OfflineMode_Tutorial_TextThree')}</ExplanationText>
                    <ExplanationText>{I18n.get().t('OfflineMode_Tutorial_TextFour')}</ExplanationText>
                    <ExplanationText>{I18n.get().t('OfflineMode_Tutorial_TextFive')}</ExplanationText>
                </ul>
            </ExplanationContainer>
        </OfflineIntroViewContainer>
    );
};
