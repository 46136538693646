import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import I18n from '../../../helper/Localization';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { BasicDialog } from '../BasicDialog';
import { OfflineTestResultCard } from './OfflineTestResultCard';
import { OrderPositionTable } from './OrderPositionTable';
import { msalApp } from '../../../helper/AuthHelper';
import { postToApi } from '../../../helper/ApiHelper';
import { BusySpinnerOverlay } from '../../busyIndicators/BusySpinnerOverlay';

const PanelBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 45px;
    height: calc(100vh - 150px);
    position: relative;
    @media only screen and (max-width: 1800px) {
        padding: 15px 25px;
    }
`;

const PanelFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 45px;
    @media only screen and (max-width: 1800px) {
        padding: 15px 25px;
    }
`;

const OrderPositionTableWrapper = styled.div`
    display: flex;
    flex: 1;
    height: calc(100vh - 410px);
    margin-top: 45px;
    @media only screen and (max-width: 1800px) {
        height: calc(100vh - 400px);
    }
`;

/**
 * Dialog component to connect existing offline test results to a corresponding order position.
 */
export const ConnectOfflineTestsDialog = (props) => {
    /**
     * Whether this component is busy or not.
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * Whether the abort dialog is open or not.
     */
    const [isAbortDialogOpen, setIsAbortDialogOpen] = useState(false);

    /**
     * State of the current offline test results.
     */
    const [offlineTestResults, setOfflineTestResults] = useState([]);

    /**
     *  State of the currently displayed offline test result number.
     */
    const [currentOfflineTestResultNumber, setCurrentOfflineTestResultNumber] = useState(0);

    /**
     * The currently selected order position id.
     */
    const [selectedOrderPositionId, setSelectedOrderPositionId] = useState(-1);

    /**
     * State of the mail address of the currently logged in user.
     */
    const [userMail, setUserMail] = useState('');

    /**
     * The name of the currently logged in user.
     */
    const [userName, setUserName] = useState('');

    /**
     * The initial state of the locally stored offline test results.
     */
    const storedOfflineTestResults = useRef([]);

    /**
     * The offset that is used to delete processed offline test results from local storage.
     */
    const deletionOffset = useRef(1);

    /**
     * Confirm callback for the abort dialog.
     */
    const [confirmCallback, setConfirmCallback] = useState(undefined);

    /**
     * State of the search value used for filtering.
     */
    const [searchValue, setSearchValue] = useState('');

    /**
     * Toggle to apply the current search value and fetch new data.
     */
    const [searchToggle, setSearchToggle] = useState(false);

    /**
     * Initially set the email of the current logged in user.
     */
    useEffect(() => {
        const getUser = async () => {
            const _msalApp = await msalApp();
            const userMail = _msalApp.getAccount().idToken.emails[0];
            setUserMail(userMail);
            const name = _msalApp.getAccount().name;
            setUserName(name);
        };
        getUser();
    }, []);

    /**
     * Determine the available offline test results to connect.
     */
    useEffect(() => {
        if (props.isOpen === true && userMail && userMail !== '') {
            // Get all available offline test results from local storage.
            const offlineTestResults = JSON.parse(localStorage.getItem('offlineTestResults'));
            if (offlineTestResults && offlineTestResults.length > 0) {
                // Filter the saved results for the current logged in user.
                storedOfflineTestResults.current = offlineTestResults.filter((offlineTestResult) => offlineTestResult.executorEmail === userMail);
                // Order the filtered offline test results by execution date.
                storedOfflineTestResults.current.sort((a, b) => {
                    return new Date(a.dateExecuted) - new Date(b.dateExecuted);
                });
                if (storedOfflineTestResults.current.length > 0) {
                    setCurrentOfflineTestResultNumber(1);
                }
                setOfflineTestResults(storedOfflineTestResults.current);
            } else {
                setCurrentOfflineTestResultNumber(0);
                setOfflineTestResults([]);
            }
        }
    }, [props.isOpen, userMail, setUserMail]);

    /**
     * Styles of the finish button.
     */
    const finishButtonStyles = {
        root: {
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Reset the states and close the dialog.
     */
    const closePanel = () => {
        setOfflineTestResults([]);
        setSelectedOrderPositionId(-1);
        setCurrentOfflineTestResultNumber(0);
        setIsAbortDialogOpen(false);
        setSearchValue('');
        storedOfflineTestResults.current = [];
        deletionOffset.current = 1;
        props.toggleHideDialog();
    };

    /**
     * Continue with the processing of the next offline test result.
     */
    const continueWithNextOfflineTestResult = () => {
        // Reset order position so the user need to choose again.
        setSelectedOrderPositionId(-1);
        if (currentOfflineTestResultNumber + 1 > offlineTestResults.length) {
            closePanel();
        } else {
            // Progress to the next offline test result;
            setCurrentOfflineTestResultNumber(currentOfflineTestResultNumber + 1);
        }
    };

    /**
     * Callback to delete the current offline test result.
     */
    const deleteCallback = () => {
        // Delete the current test result.
        const tmp = [...storedOfflineTestResults.current];
        tmp.splice(currentOfflineTestResultNumber - deletionOffset.current, 1);
        deletionOffset.current = deletionOffset.current + 1;
        storedOfflineTestResults.current = tmp;
        // Update the local storage.
        localStorage.setItem('offlineTestResults', JSON.stringify(storedOfflineTestResults.current));
        // Continue with the next offline test result to connect.
        continueWithNextOfflineTestResult();
        setIsAbortDialogOpen(false);
    };

    /**
     * Delete the current offline test result.
     */
    const deleteOfflineTestResult = () => {
        setConfirmCallback('delete');
        setIsAbortDialogOpen(true);
    };

    /**
     * Callback to connect the current offline test result.
     */
    const connectCallback = async () => {
        setIsBusy(true);
        // Build the test result based on the user selected order position.
        const newTestResult = {
            dateExecuted: offlineTestResults[currentOfflineTestResultNumber - 1].dateExecuted,
            measurementLeftWithoutProtection: offlineTestResults[currentOfflineTestResultNumber - 1].measurementLeftWithoutProtection,
            measurementLeftWithProtection: offlineTestResults[currentOfflineTestResultNumber - 1].measurementLeftWithProtection,
            measurementRightWithoutProtection: offlineTestResults[currentOfflineTestResultNumber - 1].measurementRightWithoutProtection,
            measurementRightWithProtection: offlineTestResults[currentOfflineTestResultNumber - 1].measurementRightWithProtection,
            insulationLeft: offlineTestResults[currentOfflineTestResultNumber - 1].insulationLeft,
            insulationRight: offlineTestResults[currentOfflineTestResultNumber - 1].insulationRight,
            isSuccessfulLeft: offlineTestResults[currentOfflineTestResultNumber - 1].isSuccessfulLeft,
            isSuccessfulRight: offlineTestResults[currentOfflineTestResultNumber - 1].isSuccessfulRight,
            orderPositionId: selectedOrderPositionId,
            testerName: userName,
        };
        try {
            // Save the connected test result.
            await postToApi('v1.0/TestResult', newTestResult);
            // Delete the current test result.
            const tmp = [...storedOfflineTestResults.current];
            tmp.splice(currentOfflineTestResultNumber - deletionOffset.current, 1);
            deletionOffset.current = deletionOffset.current + 1;
            storedOfflineTestResults.current = tmp;
            // Update the local storage.
            localStorage.setItem('offlineTestResults', JSON.stringify(storedOfflineTestResults.current));
            // Reset the search value.
            setSearchValue('');
            setSearchToggle(!searchToggle);
        } catch (error) {
            console.error(error);
        } finally {
            setIsBusy(false);
        }
        // Continue with the next offline test result to connect.
        continueWithNextOfflineTestResult();
        setIsAbortDialogOpen(false);
    };

    /**
     * Connect the current test result with the selected order position.
     */
    const connectOfflineTestResult = () => {
        setConfirmCallback('connect');
        setIsAbortDialogOpen(true);
    };

    /**
     * Body of the panel.
     */
    const onRenderBody = () => (
        <PanelBody>
            <BusySpinnerOverlay isBusy={isBusy} />
            <OfflineTestResultCard
                offlineTestResult={offlineTestResults && offlineTestResults[currentOfflineTestResultNumber - 1]}
                currentTestResultNumber={currentOfflineTestResultNumber}
                testResultsCount={offlineTestResults.length}
                delete={deleteOfflineTestResult}
                connect={connectOfflineTestResult}
                isConnectDisabled={selectedOrderPositionId === -1}
            />
            <OrderPositionTableWrapper>
                <OrderPositionTable
                    currentOrderPositionId={selectedOrderPositionId}
                    updateSelectedOrderPositionId={(id) => setSelectedOrderPositionId(id)}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    searchToggle={searchToggle}
                    setSearchToggle={setSearchToggle}
                />
            </OrderPositionTableWrapper>
        </PanelBody>
    );

    /**
     * Footer content of the panel.
     */
    const onRenderFooter = () => (
        <PanelFooter>
            <PrimaryButton disabled={isBusy} styles={finishButtonStyles} text={I18n.get().t('ConnectOfflineTestsDialog_Close')} onClick={closePanel} />
        </PanelFooter>
    );

    return (
        <>
            <Panel
                allowTouchBodyScroll
                headerText={I18n.get().t('ConnectOfflineTestsDialog_HeaderText')}
                closeButtonAriaLabel={I18n.get().t('ConnectOfflineTestsDialog_Close')}
                type={PanelType.large}
                isOpen={props.isOpen}
                onDismiss={closePanel}
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooter}
                isFooterAtBottom
                onOuterClick={() => {
                    // NOTE: This is needed to prevent the panel from closing when the user interacts with the dialog.
                }}
            />
            <BasicDialog
                hidden={!isAbortDialogOpen}
                title={I18n.get().t('AbortConnectOfflineTests_Headline')}
                text={I18n.get().t('AbortConnectOfflineTests_Text')}
                confirmCallback={confirmCallback === 'delete' ? deleteCallback : connectCallback}
                cancelCallback={() => setIsAbortDialogOpen(false)}
            />
        </>
    );
};
