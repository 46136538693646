import React from 'react';
import styled from 'styled-components/macro';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

const BusySpinnerContainer = styled.div`
    display: flex;
`;

/**
 * Basic busy spinner component.
 */
export const BusySpinner = (props) => {
    return (
        <>
            {props.isBusy ? (
                <BusySpinnerContainer>
                    <Spinner size={props.busySize ? props.busySize : SpinnerSize.large} />
                </BusySpinnerContainer>
            ) : null}
        </>
    );
};
