import React from 'react';
import styled from 'styled-components/macro';
import I18n from '../../helper/Localization';
import { PrimaryButton, DefaultButton, getTheme } from 'office-ui-fabric-react';
import { useStoreState } from 'easy-peasy';

const ResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.backgroundColor};
`;

const HeadlineContainer = styled.div`
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    color: ${(props) => props.fontColor};
    font-size: 40px;
    font-weight: bold;
    @media only screen and (max-width: 1400px) {
        font-size: 23px;
    }
`;

const TextContainer = styled.div`
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    color: ${(props) => props.fontColor};
    font-size: 30px;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 35px;
`;

/**
 * view component that contains the failed result of the hearing test
 */
export const TestFailedView = (props) => {
    const theme = getTheme();

    /** Whether the CH features are enabled or not. */
    const enableChFeatures = useStoreState((state) => state.ui.enableChFeatures);

    /**
     * Retrieve the store state of the offline mode.
     */
    const isOfflineModeEnabled = useStoreState((state) => state.ui.isOfflineModeEnabled);

    const restartButtonStyle = {
        root: {
            border: 0,
            fontSize: '15px',
            minWidth: '179px',
            height: '39px',
            backgroundColor: theme.palette.neutralSecondary,
            color: theme.palette.white,
        },
        rootHovered: {
            backgroundColor: 'none',
            color: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
            color: 'none',
        },
    };

    const leaveButtonStyle = {
        root: {
            borderRadius: '5px',
            fontSize: '15px',
            border: 0,
            minWidth: 'max-content',
            width: '200px',
            height: '39px',
        },
        rootHovered: {
            backgroundColor: 'none',
            color: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
            color: 'none',
        },
    };

    return (
        <ResultContainer backgroundColor={theme.palette.neutralSecondary}>
            <HeadlineContainer fontColor={theme.palette.white}>{I18n.get().t('Test_Result_HeadlineFailed')}</HeadlineContainer>
            <TextContainer fontColor={theme.palette.white}>
                {isOfflineModeEnabled ? I18n.get().t('Test_Result_TextFailed_Offline') : I18n.get().t('Test_Result_TextFailed')}
            </TextContainer>
            <TextContainer fontColor={theme.palette.white}>
                {I18n.get().t('Test_Result_TextFailed_UsageNote')}
                <br />
                {I18n.get().t('Test_Result_TextFailed_ContactInfo')}
                <br />
                {enableChFeatures ? (
                    <>
                        {I18n.get().t('Test_Result_TextFailed_ContactDetails_T_CH')}
                        <br /> {I18n.get().t('Test_Result_TextFailed_ContactDetails_E_CH')}
                        <br /> {I18n.get().t('Test_Result_TextFailed_ContactDetails_W_CH')}
                    </>
                ) : (
                    <>
                        {I18n.get().t('Test_Result_TextFailed_ContactDetails_T_DE')}
                        <br /> {I18n.get().t('Test_Result_TextFailed_ContactDetails_E_DE')}
                        <br /> {I18n.get().t('Test_Result_TextFailed_ContactDetails_W_DE')}
                    </>
                )}
            </TextContainer>
            <BottomContainer>
                {!isOfflineModeEnabled && <DefaultButton styles={restartButtonStyle} text={I18n.get().t('Test_Result_Restart')} onClick={props.restart} />}
                <PrimaryButton
                    styles={leaveButtonStyle}
                    text={isOfflineModeEnabled ? I18n.get().t('OfflineMode_TestFinish_Button') : I18n.get().t('Test_Result_Leave')}
                    onClick={props.abort}
                />
            </BottomContainer>
        </ResultContainer>
    );
};
