/**
 * enum for possible user operating systems
 */
export const os = {
  Windows: 0,
  Mac: 1,
  Linux: 2,
  Unix: 3,
};

/**
 * detects the operating system of the user and returns the os enum constant
 */
export const determineOperatingSystem = () => {
  if (navigator.appVersion.indexOf('Win') !== -1) {
    return os.Windows;
  }
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return os.Mac;
  }
  if (navigator.appVersion.indexOf('X11') !== -1) {
    return os.Unix;
  }
  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return os.Linux;
  }
};
