import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import History from './BrowserHistory';

const aiPromise = fetch('api/Configuration/AppInsightsKey').then((key) => key.json());
const reactPlugin = new ReactPlugin();
let appInsights;

aiPromise.then((aiKey) => {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: aiKey,
            disableFetchTracking: false,
            enableAutoRouteTracking: true,
            enableRequestHeaderTracking: true,
            autoTrackPageVisitTime: true,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: History },
            },
        },
    });
    appInsights.loadAppInsights();
});

export { reactPlugin, appInsights };
