import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import Avatar from './Avatar';
import { UserMenu } from './UserMenu';
import UvexLogo from '../assets/uvex.svg';
import { OfflineSwitch } from './OfflineSwitch';
import { LanguageSelector } from './LanguageSelector';
import { useStoreState } from 'easy-peasy';
import { Separator } from '@fluentui/react/lib/Separator';
import { Colors } from '../styles/Globals';
import { ORDERSSORTASCENDING, ORDERSSORTPROPERTYNAME } from '../helper/GlobalConstants';

const TopMenuContainer = styled.div`
    display: flex;
    position: fixed;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #d2d2d2;
    background-color: white;
    width: 100%;
    z-index: 9996;
    height: var(--top-menu-height);
`;
const LogoContainer = styled.div`
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
    align-items: center;
    cursor: pointer;
`;

const OfflineSwitchWrapper = styled.div`
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LanguageMenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    height: 20px;
`;

const UserMenuWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
`;

/** Top menu bar with logo and username. */
export const TopMenu = () => {
    /**
     * Whether the user menu is open or not.
     */
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

    /** Whether the CH features are enabled or not. */
    const enableChFeatures = useStoreState((state) => state.ui.enableChFeatures);

    /**
     * Callback for the click event listener.
     */
    const clickListener = useCallback(() => {
        if (isUserMenuOpen) {
            setIsUserMenuOpen(false);
        }
    }, [isUserMenuOpen]);

    /**
     * Add an event listener to close the user menu.
     */
    useEffect(() => {
        // Attach the listeners on component mount.
        document.addEventListener('click', clickListener);
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener('click', clickListener);
        };
    }, [clickListener]);

    const separatorStyles = {
        root: {
            height: '55%',
            padding: '0 15px',
            '::after': {
                backgroundColor: Colors.borderGray,
            },
        },
    };

    return (
        <TopMenuContainer>
            <LogoContainer>
                <Logo src={UvexLogo} onClick={() => { sessionStorage.removeItem(ORDERSSORTPROPERTYNAME); sessionStorage.removeItem(ORDERSSORTASCENDING); window.location.reload(); } }></Logo>
                <OfflineSwitchWrapper>
                    <OfflineSwitch />
                </OfflineSwitchWrapper>
            </LogoContainer>
            <RightContainer>
                <LanguageMenuWrapper>{enableChFeatures && <LanguageSelector />}</LanguageMenuWrapper>
                {enableChFeatures && <Separator vertical styles={separatorStyles} />}
                <UserMenuWrapper onClick={() => setIsUserMenuOpen(true)}>
                    <Avatar />
                    <UserMenu isVisible={isUserMenuOpen} />
                </UserMenuWrapper>
            </RightContainer>
        </TopMenuContainer>
    );
};
