import * as React from 'react';
import I18n from '../../helper/Localization';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ContextualMenu } from 'office-ui-fabric-react/lib/ContextualMenu';
import { useId, useBoolean } from '@uifabric/react-hooks';

/**
 * Basic dialog with custom headline, text and confirm / cancel callbacks
 * @param props the hidden status, the headline text, the sub-text, the confirm callback and the cancel callback.
 */
export const BasicDialog = (props) => {
    const [isDraggable] = useBoolean(false);
    const labelId = useId('dialogLabel');
    const subTextId = useId('subTextLabel');

    const dialogContentProps = {
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: 'Close',
        subText: props.text,
    };
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            dragOptions: isDraggable
                ? {
                      moveMenuItemText: 'Move',
                      closeMenuItemText: 'Close',
                      menu: ContextualMenu,
                  }
                : undefined,
        }),
        [isDraggable, labelId, subTextId]
    );

    const cancleButtonStyles = {
        root: {
            border: 'unset',
        },
    };

    return (
        <>
            <Dialog minWidth={370} hidden={props.hidden} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={props.confirmCallback} text={I18n.get().t('AbortDialog_Confirm')} />
                    <DefaultButton styles={cancleButtonStyles} onClick={props.cancelCallback} text={I18n.get().t('AbortDialog_Cancel')} />
                </DialogFooter>
            </Dialog>
        </>
    );
};
