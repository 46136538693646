import Roles from '../types/roles';

/**
 * Checks if the user is a global admin or an admin for at least one debtor.
 * @param {*} userRoles
 */
export const isUserAdministrator = (userRoles) => {
    return userRoles.some((r) => r.debtorId == null && r.role.label === Roles.Administrator) || userRoles.map((r) => r.role.label).includes(Roles.Administrator);
};

/**
 * Checks if the user is a global account manager or an account manager for at least one debtor.
 * @param {*} userRoles
 */
export const isUserAccountManager = (userRoles) => {
    return userRoles.some((r) => r.debtorId == null && r.role.label === Roles.AccountManager) || userRoles.map((r) => r.role.label).includes(Roles.AccountManager);
};

/**
 * Checks if the user has a 'User' role assigned for at least one debtor or a global user.
 * @param {*} userRoles
 */
export const isUserUser = (userRoles) => {
    return userRoles.some((r) => r.debtorId == null && r.role.label === Roles.User) || userRoles.map((r) => r.role.label).includes(Roles.User);
};
