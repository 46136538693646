import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import History from '../components/BrowserHistory';

/** Localization class initiating i18n. */
export default class Localization {
    /** i18n instance. */
    i18nInstance;

    /** Promise for the loading i18next instance. */
    initPromise;

    /** Singleton instance. */
    static instance;

    /**
     * Loads the i18n requested languages from the localization api.
     *
     * @param requestedLanguage The ISO-code for the language to load.
     * @param callback The callback to execute when the languages are returned from the api.
     * @param options ajax options.
     * @param payload the requested language.
     */
    loadLocales = async (options, requestedLanguage, payload, callback) => {
        const validLngKeys = ['de', 'en', 'fr'];
        try {
            if (!navigator.onLine) {
                const result = localStorage.getItem('localization');
                callback(null, { status: 200, data: JSON.parse(result) });
                return;
            }
            if (!validLngKeys.includes(requestedLanguage.toLowerCase())) {
                // do nothing when a language is requested, that isn't the one the user selected
                callback(null, { status: 200 });
                return;
            }
            this.options.lng = requestedLanguage;
            const localeResult = await fetch(`api/Localization/Get?culture=${this.options.lng}`);
            const locale = await localeResult.json();
            localStorage.setItem('localization', JSON.stringify(locale));
            localStorage.setItem('lng', requestedLanguage);
            callback(null, { status: 200, data: locale });
        } catch (error) {
            console.error(`Failed language: ${requestedLanguage}. Error: ${error.message}`);
            callback(error, { status: 404 });
        }
    };

    /** I18n Options. */
    options = {
        backend: {
            request: this.loadLocales,
            allowMultiLoading: false,
            requestOptions: {
                mode: 'cors',
                credentials: 'same-origin',
                cache: 'default',
            },
            loadPath: '{{lng}}',
            parse: (data) => data,
        },
        cache: {
            enabled: true,
        },
        debug: false,
        defaultNS: 'common',
        initImmediate: false,
        interpolation: {
            escapeValue: false,
        },
        ns: ['common'],
        react: {
            nsMode: 'default',
            wait: true,
        },
    };

    constructor(initialLng) {
        if (initialLng && initialLng !== 0) {
            switch (initialLng) {
                case 1:
                    this.options.lng = 'en';
                    break;
                case 2:
                    this.options.lng = 'de';
                    break;
                case 3:
                    this.options.lng = 'fr';
                    break;
                default:
                    this.options.lng = 'de';
                    break;
            }
        } else {
            const storedLanguageKey = localStorage.getItem('lng');
            this.options.lng = storedLanguageKey ? storedLanguageKey : 'de';
        }
        this.initPromise = i18n.use(HttpApi).use(initReactI18next).init(this.options);
        this.initPromise.then(() => (this.i18nInstance = i18n));
    }

    /** Retrieves the single i18n instance. */
    static get() {
        const inst = this.getInstance();
        while (inst.i18nInstance == null) {
            // wait for i18n to load...
        }
        return inst.i18nInstance;
    }

    /** Retrieves the promise for the i18n instance. */
    static getPromise(initialLng) {
        return this.getInstance(initialLng).initPromise;
    }

    /** Retrieves the singleton instance. */
    static getInstance(initialLng) {
        if (initialLng !== undefined && initialLng !== this.instance?.options?.lng) {
            this.instance = new this(initialLng);
        }
        return this.instance || (this.instance = new this(initialLng));
    }

    /**
     * Change the language.
     *
     * @param {string} lng The targeted language.
     * @param {Function} callback The callback to execute.
     */
    static changeLanguage(lng, callback) {
        this.get().changeLanguage(lng, () => {
            if (callback) {
                callback();
            }
            History.push(`/refresh${History.location.pathname}`);
        });
    }
}
