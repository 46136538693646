import React from 'react';
import { StatusIcon, Status } from './StatusIcon';
import styled from 'styled-components/macro';
import I18n from '../helper/Localization';

const TeachingBubbleContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const TeachingBubbleRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
`;

const StatusIconWrapper = styled.div`
    margin-right: 15px;
`;

/**
 * Content of the teaching bubble that explains the test status icons.
 */
export const TestStatusTeachingBubbleContent = () => {
    return (
        <TeachingBubbleContent>
            <TeachingBubbleRow>
                <StatusIconWrapper>
                    <StatusIcon status={Status.Pending} />
                </StatusIconWrapper>
                {I18n.get().t('InfoButton_Dialog_Open')}
            </TeachingBubbleRow>
            <TeachingBubbleRow>
                <StatusIconWrapper>
                    <StatusIcon status={Status.Success} />
                </StatusIconWrapper>
                {I18n.get().t('InfoButton_Dialog_Success')}
            </TeachingBubbleRow>
            <TeachingBubbleRow>
                <StatusIconWrapper>
                    <StatusIcon status={Status.Error} />
                </StatusIconWrapper>
                {I18n.get().t('InfoButton_Dialog_Error')}
            </TeachingBubbleRow>
            <TeachingBubbleRow>
                <StatusIconWrapper>
                    <StatusIcon status={Status.Returning} />
                </StatusIconWrapper>
                {I18n.get().t('InfoButton_Dialog_Returning')}
            </TeachingBubbleRow>
        </TeachingBubbleContent>
    );
};
