import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import I18n from '../../helper/Localization';
import { PrimaryButton, getTheme } from 'office-ui-fabric-react';
import Table from '../Table';
import { postToApiBlob } from '../../helper/ApiHelper';
import BusyIndicator from '../busyIndicators/busyIndicator';
import { Colors } from '../../styles/Globals';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';
import { BusySpinner } from '../busyIndicators/BusySpinner';
import { SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { CustomSearchBox } from '../CustomSearchBox';
import { PaginationControls } from '../PaginationControls';
import { useStoreState } from 'easy-peasy';

/**
 * styled components
 */
const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const SearchBoxContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 30px;
`;

const ExportBusyWrapper = styled.div`
    margin-right: 10px;
`;

const BusyContainer = styled.div`
    width: 100%;
    height: 10px;
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Content = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    margin: 40px 60px 0px 60px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 1px 2px 6px ${Colors.boxShadow};
    display: flex;
    flex: 1;
    @media only screen and (max-width: 1400px) {
        margin: 10px 20px 0px 20px;
        max-height: calc(100% - 130px);
    }
`;

const ControlsContainer = styled.div`
    display: flex;
    padding: 5px 60px;
    height: 50px;
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid #d2d2d2;
    width: 100%;
    @media only screen and (max-width: 1400px) {
        padding: 5px 20px;
    }
`;

const TitleContainer = styled.div`
    font-size: 26px;
    font-weight: bold;
    color: ${(props) => props.fontColor};
    @media only screen and (max-width: 1400px) {
        font-size: 23px;
    }
`;

/**
 * A unified and customizable table component
 */
export const TableView = (props) => {
    const theme = getTheme();

    /**
     * The state of the preferred user language.
     */
    const userPreferredLanguage = useStoreState((state) => state.user.user.preferredLanguage);

    /**
     * The current value of the searchbox text.
     */
    const [searchValue, setSearchValue] = useState(props.searchValue);

    /**
     * The moment of the last data update.
     */
    const [lastDataUpdate, setLastDataUpdate] = useState(new Date());

    /**
     * Whether the excel export is busy or not.
     */
    const [isExportBusy, setIsExportBusy] = useState(false);

    /**
     * The abort controller.
     */
    const controller = useMemo(() => new AbortController(), []);

    /**
     * Component will unmount, so abort.
     */
    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [controller]);

    /**
     * Styles of the standard used button.
     */
    const exportButtonStyles = {
        root: {
            height: '24px',
            width: '120px',
            minWidth: '120px',
            marginLeft: 10,
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            fontSize: 12,
            fontWeight: 'bold',
        },
    };

    /**
     * callback function for the xlsx export
     */
    const exportXlsx = async () => {
        setIsExportBusy(true);
        const date = new Date();
        const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const [{ value: month }, , { value: day }, , { value: year }] = dateTimeFormat.formatToParts(date);
        const today = `${year}-${month}-${day}`;
        // create the export filename
        const fileName = `${today}_${I18n.get().t('ExcelExport_FileName')}_${props.viewName.replace('é', 'e')}.xlsx`;
        const languageKeys = ['en', 'de', 'fr'];
        const reqBody = {
            filterText: searchValue,
            fileName: fileName,
            worksheetName: props.viewName,
            clientCulture: languageKeys[userPreferredLanguage],
        };
        let endpoint = null;
        switch (props.viewKey) {
            case 'users':
                endpoint = 'users';
                break;
            case 'orders':
                endpoint = 'orders';
                break;
            case 'results':
                endpoint = 'results';
                break;
            default:
                console.error('Unknown view key:', props.viewKey);
        }
        await postToApiBlob('v1.0/Export/' + endpoint, JSON.stringify(reqBody), fileName, controller.signal);
        setIsExportBusy(false);
    };

    /**
     * Apply the search value and reset the state of the current displayed page to one.
     */
    const applySearch = () => {
        props.setCurrentPage(1);
        props.setSearchValue(searchValue);
    };

    return (
        <FlexColumnContainer>
            <ControlsContainer>
                <TitleContainer fontColor={theme.palette.black}>{props.viewName}</TitleContainer>
                <SearchBoxContainer>
                    <CustomSearchBox
                        width={400}
                        isBusy={props.isSearchBusy}
                        placeholder={I18n.get().t('Placeholder_SearchBox')}
                        busyText={I18n.get().t('Placeholder_SearchBox_Busy')}
                        searchValue={searchValue}
                        updateSearchValue={(newValue) => setSearchValue(newValue)}
                        updateLastDataUpdate={() => setLastDataUpdate(new Date())}
                        applySearch={applySearch}
                    />
                </SearchBoxContainer>
                <ButtonContainer>
                    <PrimaryButton disabled={!props.data || props.data.length <= 0} styles={exportButtonStyles} onClick={() => exportXlsx()} allowDisabledFocus>
                        {isExportBusy && (
                            <ExportBusyWrapper>
                                <BusySpinner busySize={SpinnerSize.small} isBusy={isExportBusy} />
                            </ExportBusyWrapper>
                        )}
                        {isExportBusy ? I18n.get().t('ExcelExport_Button_BusyText') : I18n.get().t('ExcelExport_Button_Text')}
                    </PrimaryButton>
                </ButtonContainer>
            </ControlsContainer>
            <BusyContainer>
                <BusyIndicator isVisible={props.isBusy || props.isBusyAlt || isExportBusy} />
            </BusyContainer>
            <Content>
                <BusySpinnerOverlay isBusy={props.isBusy} />
                <Table
                    data={props.data}
                    columns={props.columns}
                    lastDataUpdate={lastDataUpdate}
                    disableSort={true}
                    onRowClick={(row) => {
                        props.rowClick && props.rowClick(row);
                    }}
                />
            </Content>
            <PaginationControls
                currentPage={props.currentPage}
                maxPages={props.maxPages}
                navigateBackwards={() => props.setCurrentPage(props.currentPage - 1)}
                navigateToBegin={() => props.setCurrentPage(1)}
                navigateToEnd={() => props.setCurrentPage(props.maxPages)}
                navigateForward={() => props.setCurrentPage(props.currentPage + 1)}
            />
        </FlexColumnContainer>
    );
};
