import React from 'react';
import { IconButton, getTheme } from 'office-ui-fabric-react';

/**
 * a basic icon button with styled play icon that takes the callback function as prop
 * @param props
 */
export const StartButton = (props) => {
    const theme = getTheme();

    const startButtonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 28,
            height: 28,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
    };
    return (
        <IconButton disabled={props.disabled} styles={startButtonStyles} iconProps={{ iconName: 'TriangleSolidRight12' }} title="Start" ariaLabel="Start" onClick={props.start} />
    );
};
