import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import Table from '../../Table';
import { Mono } from '../../../styles/Globals';
import I18n from '../../../helper/Localization';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { postToApi } from '../../../helper/ApiHelper';
import { CustomSearchBox } from '../../CustomSearchBox';
import { BusySpinnerOverlay } from '../../busyIndicators/BusySpinnerOverlay';
import { PaginationControls } from '../../PaginationControls';
import { IconButton } from 'office-ui-fabric-react';

const OrderPositionTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const SortableTableHeader = styled.div`
    cursor: pointer;
`;

const TableWrapper = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    position: relative;
`;

const Headline = styled.div`
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: bold;
`;

/**
 * The order position table used to connect offline test results.
 */
export const OrderPositionTable = (props) => {
    /** Request abort controller. */
    const abortController = new AbortController();

    /**
     * State of all available order position.
     */
    const [orderPositions, setOrderPositions] = useState([]);

    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * Whether the table search is busy or not.
     */
    const [isSearchBusy, setIsSearchBusy] = useState(false);

    /**
     * The maximum number of pages of the current fetched data.
     */
    const [maxPages, setMaxPages] = useState(1);

    /**
     * The current selected data page.
     */
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * State of the current sort direction. true for ASC, false for DESC.
     */
    const [sortAscending, setSortAscending] = useState(true);

    /**
     * State of the current sort property.
     */
    const [sortPropertyName, setSortPropertyName] = useState('OrderDeliveryInvoiceNumber');

    /**
     * Whether the user has sorted the table or not.
     */
    const hasSorted = useRef(false);

    const previousSearchValue = useRef('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsBusy(true);
                const defaultReqBody = {
                    itemsPerPage: 25,
                    sortDirection: sortAscending ? 1 : 2,
                    sortPropertyName: sortPropertyName,
                };
                let reqBody;
                if (props.searchValue && props.searchValue !== '') {
                    setIsSearchBusy(true);
                    if (props.searchValue !== previousSearchValue.current) {
                        reqBody = {
                            filterText: props.searchValue.toLowerCase(),
                            pageToDeliver: 1,
                            ...defaultReqBody,
                        };
                    } else {
                        reqBody = {
                            filterText: props.searchValue.toLowerCase(),
                            pageToDeliver: currentPage,
                            ...defaultReqBody,
                        };
                    }
                } else {
                    if (previousSearchValue.current !== '') {
                        setIsSearchBusy(true);
                    }
                    reqBody = {
                        pageToDeliver: currentPage,
                        ...defaultReqBody,
                    };
                }
                const result = await postToApi('v1/OrderPosition/Paged', reqBody, abortController.signal);
                setMaxPages(result.pagesAvailable);
                setOrderPositions(result.results);
            } catch (error) {
                setMaxPages(1);
                setCurrentPage(1);
                setOrderPositions([]);
            } finally {
                previousSearchValue.current = props.searchValue;
                setIsBusy(false);
                setIsSearchBusy(false);
            }
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, setCurrentPage, props.searchToggle, props.setSearchToggle, sortAscending, setSortAscending, sortPropertyName, setSortPropertyName]);

    /**
     * Sorts the table by the given property name considering the sort direction state.
     */
    const handlePropertySort = (propertyName) => {
        if (sortPropertyName === propertyName) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
            setSortPropertyName(propertyName);
        }
    };

    /**
     * Icon for an unchecked radio button.
     */
    const radioUncheckedIconProps = {
        iconName: 'RadioBtnOff',
    };

    /**
     * Icon for an checked radio button.
     */
    const radioCheckedIconProps = {
        iconName: 'RadioBtnOn',
    };

    const radioButtonStyles = {
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
        rootFocused: {
            outline: 0,
        },
    };

    /**
     * Columns of the order position table.
     */
    const columns = [
        {
            Header: '',
            accessor: 'select',
            width: 50,
            Cell: (table) => (
                <IconButton
                    styles={radioButtonStyles}
                    iconProps={props.currentOrderPositionId && props.currentOrderPositionId === table.row.original.id ? radioCheckedIconProps : radioUncheckedIconProps}
                    onClick={() => props.updateSelectedOrderPositionId(table.row.original.id)}
                />
            ),
        },
        {
            Header: (
                <SortableTableHeader onClick={() => handlePropertySort('Wearer')}>
                    {I18n.get().t('Table_Header_Supporter')}
                    {sortPropertyName === 'Wearer' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'wearer',
            width: 150,
        },
        {
            Header: (
                <SortableTableHeader
                    onClick={() => {
                        hasSorted.current = true;
                        handlePropertySort('OrderDeliveryInvoiceNumber');
                    }}
                >
                    {I18n.get().t('Table_Header_Number')}
                    {hasSorted.current !== false && sortPropertyName === 'OrderDeliveryInvoiceNumber' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'orderDeliveryInvoiceNumber',
            width: 150,
            Cell: (table) => <Mono>{table.cell.value}</Mono>,
        },
        {
            Header: (
                <SortableTableHeader onClick={() => handlePropertySort('DebtorCompanyName')}>
                    {I18n.get().t('Table_Header_Debtor')}
                    {sortPropertyName === 'DebtorCompanyName' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'debtorCompanyName',
            width: 300,
        },
    ];

    return (
        <OrderPositionTableContainer>
            <Headline>{I18n.get().t('OrderPositionTable_Headline')}</Headline>
            <div>
                <CustomSearchBox
                    width={'100%'}
                    isBusy={isSearchBusy}
                    placeholder={I18n.get().t('OrderPositionTable_Placeholder_SearchBox')}
                    busyText={I18n.get().t('Placeholder_SearchBox_Busy')}
                    searchValue={props.searchValue}
                    updateSearchValue={(newValue) => props.setSearchValue(newValue)}
                    applySearch={() => {
                        props.setSearchToggle(!props.searchToggle);
                        setCurrentPage(1);
                    }}
                />
            </div>
            <TableWrapper>
                <BusySpinnerOverlay isBusy={isBusy} />
                <Table data={orderPositions} columns={columns} disableSort={true} />
            </TableWrapper>
            <PaginationControls
                currentPage={currentPage}
                maxPages={maxPages}
                navigateBackwards={() => setCurrentPage(currentPage - 1)}
                navigateToBegin={() => setCurrentPage(1)}
                navigateToEnd={() => setCurrentPage(maxPages)}
                navigateForward={() => setCurrentPage(currentPage + 1)}
            />
        </OrderPositionTableContainer>
    );
};
