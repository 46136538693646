import React, { useEffect, useState } from 'react';
import { useBoolean } from '@uifabric/react-hooks';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import InviteUserDialog from './dialogs/inviteUser/InviteUserDialog';
import History from './BrowserHistory';
import { useStoreState, useStoreActions } from 'easy-peasy';
import styled from 'styled-components/macro';
import { DefaultButton, PrimaryButton, DirectionalHint, getTheme } from 'office-ui-fabric-react';
import { getLocationFromPath } from '../helper/NavigationHelper';
import { isUserAdministrator, isUserAccountManager, isUserUser } from '../helper/RoleHelper';
import '../styles/nav/nav.scss';
import I18n from '../helper/Localization';
import { ConnectOfflineTestsDialog } from './dialogs/connectOfflineTestsDialog/ConnectOfflineTestsDialog';
import { msalApp } from '../helper/AuthHelper';
import { ConfirmationDialog } from './dialogs/manualOrderDialog/ConfirmationDialog';
import { ManualOrderDialog } from './dialogs/manualOrderDialog/ManualOrderDialog';

const NavContainer = styled.div`
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(props) => props.theme.palette.black};
    overflow: hidden;
    border-top: unset;
    z-index: 9996;
    top: var(--top-menu-height);
    height: calc(100% - var(--top-menu-height));
    width: ${(props) => (props.open ? 'var(--nav-menu-open-width);' : 'var(--nav-menu-closed-width);')};
`;

const NavControls = styled.div`
    display: flex;
    flex-direction: column;
`;

const OfflineTestResultIndicator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 3px;
    left: ${(props) => props.absoluteLeft};
    background-color: white;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    min-width: max-content;
    background-color: ${(props) => props.backgroundColor};
`;

const OfflineTestResultLengthText = styled.div`
    padding: 3px;
    font-size: ${(props) => props.fontSize};
`;

const NavMenu = () => {
    const menuOpen = useStoreState((state) => state.ui.menuOpen);
    const toggleMenu = useStoreActions((actions) => actions.ui.toggleMenu);
    const currentNavKey = useStoreState((state) => state.ui.currentNavKey);
    const changeCurrentNavKey = useStoreActions((actions) => actions.ui.changeCurrentNavKey);
    const [isUserInviteDialogOpen, { toggle: toggleUserInviteDialog }] = useBoolean(true);
    const userRoles = useStoreState((state) => state.user.roles);
    const isAdministrator = isUserAdministrator(userRoles);
    const isAccountManager = isUserAccountManager(userRoles);
    const isUser = isUserUser(userRoles);
    const [isConnectOfflineTestsDialogOpen, setIsConnectOfflineTestsDialogOpen] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isManualOrderDialogOpen, setIsManualOrderDialogOpen] = useState(false);
    const [availableOfflineTestResults, setAvailableOfflineTestResults] = useState([]);
    /**
     * Gets the Fluent UI Theme
     */
    const theme = getTheme();

    /**
     * Whether the current user has the right to invite users.
     */
    const canInviteUsers = isAdministrator || isAccountManager;

    useEffect(() => {
        changeCurrentNavKey(getLocationFromPath(History.location.pathname));
    }, [changeCurrentNavKey, currentNavKey]);

    /**
     * Determine the available offline test results to connect.
     */
    useEffect(() => {
        const determineIfOfflineTestResultsAvailable = async () => {
            // Get all available offline test results from local storage.
            let offlineTestResults = JSON.parse(localStorage.getItem('offlineTestResults'));
            if (offlineTestResults && offlineTestResults.length > 0) {
                // Get the mail address of the currently logged in user.
                const _msalApp = await msalApp();
                const userMail = _msalApp.getAccount().idToken.emails[0];
                // Filter the saved results for the current logged in user.
                offlineTestResults = offlineTestResults.filter((offlineTestResult) => offlineTestResult.executorEmail === userMail);
                if (offlineTestResults.length > 0) {
                    setAvailableOfflineTestResults(offlineTestResults);
                    return;
                }
            }
            setAvailableOfflineTestResults([]);
        };
        determineIfOfflineTestResultsAvailable();
    }, [isConnectOfflineTestsDialogOpen, setIsConnectOfflineTestsDialogOpen]);

    const navStyles = {
        root: {
            border: 'unset',
        },
        navItem: {
            height: 50,
            color: theme.palette.white,
        },
        link: {
            color: theme.palette.white,
            height: 50,
            padding: 10,
            selectors: {
                ':focus': {
                    outline: 'unset',
                },
            },
        },
    };

    const toggleButtonStyles = {
        root: {
            backgroundColor: theme.palette.black,
            border: 'none',
            padding: 0,
            height: 40,
            minWidth: 'unset',
            selectors: {
                ':focus': {
                    outline: 'unset',
                },
            },
        },
        rootHovered: {
            backgroundColor: theme.palette.black,
        },
        rootChecked: {
            backgroundColor: theme.palette.neutralDark,
        },
        rootCheckedHovered: {
            backgroundColor: theme.palette.neutralDark,
        },
        rootPressed: {
            backgroundColor: theme.palette.neutralDark,
        },
        icon: {
            color: theme.palette.white,
            marginLeft: 18,
        },
        iconChecked: {
            transform: 'rotate(180deg)',
            marginLeft: 'auto',
            marginRight: 14,
        },
        flexContainer: {
            justifyContent: menuOpen && menuOpen ? 'flex-end' : 'unset',
        },
    };

    const plusButtonStyles = {
        root: {
            position: 'relative',
            backgroundColor: theme.palette.themePrimary,
            border: 'none',
            padding: 0,
            borderRadius: 0,
            marginBottom: 3,
            height: 49,
            minWidth: 'unset',
        },
        textContainer: {
            textAlign: 'left',
        },
        rootDisabled: {
            backgroundColor: theme.palette.themePrimary,
        },
        menuIcon: {
            display: 'none',
        },
        icon: {
            marginLeft: 15,
            marginBottom: 5,
        },
        iconChecked: {
            marginLeft: 'auto',
            marginRight: 14,
        },
        flexContainer: {
            justifyContent: 'unset',
        },
        rootHovered: {
            border: 'none',
        },
        rootPressed: {
            border: 'none',
        },
    };

    const navLinkGroups = () => {
        // User might be global administrator for all debtors or for a subset of debtors.
        const links = [];
        if (isAdministrator || isAccountManager) {
            // Only admins or account manager can invite users.
            links.push({
                name: I18n.get().t('NavControls_Users'),
                icon: 'users-svg',
                key: '/users',
                target: '/users',
            });
        }
        links.push({
            name: I18n.get().t('NavControls_Orders'),
            icon: 'ClipboardSolid',
            key: '/orders',
            target: '/orders',
        });
        return [
            {
                links: links,
            },
        ];
    };

    /**
     * Function to determine the menu items for the nav controls menu.
     */
    const plusMenuItems = () => {
        const items = [];
        if (canInviteUsers) {
            // If the user has the right to invite users, add this item to the menu.
            items.push({
                key: 'newUser',
                text: I18n.get().t('NavControls_InviteUser'),
                onClick: toggleUserInviteDialog,
            });
        }
        if (isUser || isAccountManager) {
            items.push({
                key: 'connectOfflineTestResults',
                text: `${I18n.get().t('NavControls_Connect')}${availableOfflineTestResults.length > 0 ? ` (${availableOfflineTestResults.length})` : ''}`,
                onClick: () => setIsConnectOfflineTestsDialogOpen(true),
            });
        }
        items.push({
            key: 'createManualOrder',
            text: I18n.get().t('NavControls_CreateManualOrder'),
            onClick: () => setIsConfirmDialogOpen(true),
        });
        return items;
    };

    const plusMenuProps = {
        items: plusMenuItems(),
        directionalHint: DirectionalHint.rightTopEdge,
    };
    const openIcon = { iconName: 'chevron-svg' };
    const plusIcon = { iconName: 'plus-svg' };

    const toggleNavMenu = () => {
        toggleMenu(!menuOpen);
    };

    /**
     * Navigates to the selected menu entry.
     * @param {*} e The click event.
     * @param {*} item The selected menu entry.
     */
    const onItemClick = (e, item) => {
        if (currentNavKey === item.target) {
            window.location.reload();
        } else {
            History.push(item.target);
            changeCurrentNavKey(item.target);
        }
    };

    return (
        <>
            <ConnectOfflineTestsDialog isOpen={isConnectOfflineTestsDialogOpen} toggleHideDialog={() => setIsConnectOfflineTestsDialogOpen(false)} />
            {canInviteUsers && <InviteUserDialog isOpen={!isUserInviteDialogOpen} toggleHideDialog={toggleUserInviteDialog} />}
            <ConfirmationDialog
                isVisible={isConfirmDialogOpen}
                confirmCallback={() => {
                    setIsConfirmDialogOpen(false);
                    setIsManualOrderDialogOpen(true);
                }}
                cancelCallback={() => setIsConfirmDialogOpen(false)}
            />
            <ManualOrderDialog isOpen={isManualOrderDialogOpen} toggleHideDialog={() => setIsManualOrderDialogOpen(false)} />
            <NavContainer open={menuOpen} theme={theme}>
                <NavControls>
                    <PrimaryButton
                        text={menuOpen ? I18n.get().t('PlusButton_Label') : ''}
                        styles={plusButtonStyles}
                        iconProps={plusIcon}
                        menuProps={plusMenuProps}
                        split={false}
                        allowDisabledFocus
                    >
                        {availableOfflineTestResults.length > 0 && (
                            <OfflineTestResultIndicator
                                backgroundColor={theme.palette.black}
                                absoluteLeft={menuOpen ? I18n.get().t('PlusButton_Label').length * 8 + 40 + 'px' : '27px'}
                            >
                                <OfflineTestResultLengthText fontSize={availableOfflineTestResults.length < 100 ? '9px' : '7px'}>
                                    {availableOfflineTestResults.length}
                                </OfflineTestResultLengthText>
                            </OfflineTestResultIndicator>
                        )}
                    </PrimaryButton>
                    <Nav onLinkClick={onItemClick} selectedKey={currentNavKey} styles={navStyles} groups={navLinkGroups()} />
                </NavControls>
                <DefaultButton toggle styles={toggleButtonStyles} checked={menuOpen} iconProps={openIcon} onClick={toggleNavMenu} allowDisabledFocus />
            </NavContainer>
        </>
    );
};

export default NavMenu;
