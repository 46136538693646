import React, { useState } from 'react';
import styled from 'styled-components/macro';
import I18n from '../../helper/Localization';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { DefaultButton, PrimaryButton, IconButton, getTheme } from 'office-ui-fabric-react';
import { determineOperatingSystem, os } from '../../helper/OperatingSystemHelper';
import { isMobile } from 'react-device-detect';

const IntroContainer = styled.div`
    display: flex;
    flex: 1;
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 5;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    background-color: ${(props) => props.backgroundColor};
`;

const HeadlineLeftContainer = styled.div`
    padding: 56px 84px 35px 54px;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #000000;
    @media only screen and (max-width: 1800px) {
        font-size: 23px;
    }
`;

const HeadlineRightContainer = styled.div`
    padding: 56px 0 12px 54px;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    @media only screen and (max-width: 1800px) {
        font-size: 23px;
        padding: 36px 0 12px 54px;
    }
`;

const TextRightContainer = styled.div`
    padding: 0 54px 51px 54px;
    font-size: 30px;
    color: #ffffff;
    @media only screen and (max-width: 1800px) {
        font-size: 18px;
        padding: 0 54px 31px 54px;
    }
`;

const ButtonContainer = styled.div`
    padding: 20px 39px 34px 39px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: auto;
    @media only screen and (max-width: 1800px) {
        font-size: 18px;
        padding: 10px 39px 24px 39px;
    }
`;

const SlideImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 45px;
`;

const SlideImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 20px solid ${(props) => props.borderColor};
    width: 100%;
    height: auto;
    max-width: 470px;
    @media only screen and (max-width: 1800px) {
        max-width: 400px;
    }
    @media only screen and (max-width: 1400px) {
        max-width: 300px;
    }
`;

const SlideControlsContainer = styled.div`
    padding: 0 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
`;

const TutorialTextContainer = styled.div`
    display: flex;
    justify-content: center;
    color: ${(props) => props.fontColor};
    margin-top: 10px;
    font-size: 14px;
`;

const TutorialTextWarning = styled.div`
    font-size: ${(props) => props.fontSize};
`;

/**
 * view component that contains the welcome and the tutorial for setting the volume correctly
 */
export const TestIntroView = (props) => {
    const theme = getTheme();

    /**
     * Whether the component is initialized correctly.
     */
    const [tutorialProgress, setTutorialProgress] = useState(0);

    const continueButtonStyle = {
        root: {
            borderRadius: '5px',
            fontSize: '15px',
            border: 0,
            minWidth: '210px',
            height: '39px',
        },
        rootHovered: {
            backgroundColor: 'none',
            color: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
            color: 'none',
        },
    };

    const abortButtonStyle = {
        root: {
            border: 0,
            fontSize: '15px',
            minWidth: '210px',
            height: '39px',
            backgroundColor: theme.palette.black,
            color: theme.palette.white,
        },
        rootHovered: {
            backgroundColor: 'none',
            color: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
            color: 'none',
        },
    };

    const iconStylesCircle = {
        icon: {
            fontSize: '14px',
        },
        root: {
            cursor: 'default !important',
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
        rootFocused: {
            outlineWidth: 0,
        },
    };
    /**
     * The maximum steps to configure system volume.
     */
    const maxProgress = determineOperatingSystem() === os.Windows || isMobile ? 1 : 3;

    /**
     * Return the correct tutorial icon name.
     */
    const switchTutorialImage = () => {
        const currentOs = determineOperatingSystem();
        switch (tutorialProgress) {
            case 0:
                return isMobile ? 'ipad-step-one' : currentOs === os.Windows ? 'windows-step-one' : 'mac-step-one';
            case 1:
                return isMobile ? 'ipad-step-two' : currentOs === os.Windows ? 'windows-step-two' : 'mac-step-two';
            case 2:
                return currentOs === os.Mac && !isMobile ? 'mac-step-three' : '';
            case 3:
                return currentOs === os.Mac && !isMobile ? 'mac-step-four' : '';
            default:
                break;
        }
    };

    /**
     * Return the correct tutorial text.
     */
    const switchTutorialText = () => {
        const currentOs = determineOperatingSystem();
        switch (tutorialProgress) {
            case 0:
                return isMobile
                    ? I18n.get().t('Test_Intro_Tutorial_iPad_One')
                    : currentOs === os.Windows
                    ? I18n.get().t('Test_Intro_Tutorial_Windows_One')
                    : os.Mac && I18n.get().t('Test_Intro_Tutorial_Mac_One');
            case 1:
                return isMobile
                    ? I18n.get().t('Test_Intro_Tutorial_iPad_Two')
                    : currentOs === os.Windows
                    ? I18n.get().t('Test_Intro_Tutorial_Windows_Two')
                    : I18n.get().t('Test_Intro_Tutorial_Mac_Two');
            case 2:
                return currentOs === os.Mac && !isMobile ? I18n.get().t('Test_Intro_Tutorial_Mac_Three') : '';
            case 3:
                return currentOs === os.Mac && !isMobile ? I18n.get().t('Test_Intro_Tutorial_Mac_Four') : '';
            default:
                break;
        }
    };

    return (
        <IntroContainer>
            <LeftContainer>
                <HeadlineLeftContainer>
                    {I18n.get().t('Test_Intro_Welcome')}
                    {props.wearerName && `, ${props.wearerName}`}
                </HeadlineLeftContainer>
            </LeftContainer>
            <RightContainer backgroundColor={theme.palette.black}>
                <HeadlineRightContainer>{I18n.get().t('Test_Intro_Instructions')}</HeadlineRightContainer>
                <TextRightContainer>{I18n.get().t('Test_Intro_Settings')}</TextRightContainer>
                <SlideImageWrapper>
                    <SlideImageContainer borderColor={theme.palette.white}>
                        <FontIcon iconName={switchTutorialImage()} />
                    </SlideImageContainer>
                    <TutorialTextContainer fontColor={theme.palette.white}>{switchTutorialText()}</TutorialTextContainer>
                    <TutorialTextContainer fontColor={theme.palette.white}>
                        <TutorialTextWarning fontSize={'17px'} dangerouslySetInnerHTML={{ __html: I18n.get().t('Test_Intro_Tutorial_Warning') }} />
                    </TutorialTextContainer>
                </SlideImageWrapper>
                <SlideControlsContainer>
                    {Array.from(Array(maxProgress + 1), (_, i) => i + 1).map((index) => {
                        if (tutorialProgress + 1 === index) {
                            return <IconButton key={index} styles={iconStylesCircle} iconProps={{ iconName: 'slider-bubble-orange' }} />;
                        } else {
                            return <IconButton key={index} styles={iconStylesCircle} iconProps={{ iconName: 'slider-bubble-white' }} />;
                        }
                    })}
                </SlideControlsContainer>
                <ButtonContainer>
                    <DefaultButton styles={abortButtonStyle} text={I18n.get().t('Test_Instructions_Abort')} onClick={props.abort} />
                    {tutorialProgress < maxProgress ? (
                        <PrimaryButton styles={continueButtonStyle} text={I18n.get().t('Test_Intro_Next')} onClick={() => setTutorialProgress(tutorialProgress + 1)} />
                    ) : (
                        <PrimaryButton styles={continueButtonStyle} text={I18n.get().t('Test_Intro_Continue')} onClick={props.continue} />
                    )}
                </ButtonContainer>
            </RightContainer>
        </IntroContainer>
    );
};
