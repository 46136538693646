import React from 'react';
import styled from 'styled-components/macro';
import { getTheme } from 'office-ui-fabric-react';
import NavMenu from './NavMenu';
import { TopMenu } from './TopMenu';
import { useStoreState } from 'easy-peasy';
import I18n from '../helper/Localization';
import { generalOptions } from '../OnlineApp';

const LayoutContainer = styled.div`
    --top-menu-height: 50px;
    --nav-menu-closed-width: 50px;
    --nav-menu-open-width: 140px;
    --footer-height: 50px;
    height: 100%;
`;

const ContentContainer = styled.div`
    background-color: #f7f7f7;
    padding-left: ${(props) => (props.menuOpen ? 'var(--nav-menu-open-width);' : 'var(--nav-menu-closed-width);')};
    padding-top: var(--top-menu-height);
    padding-bottom: var(--footer-height);
    width: 100%;
    height: 100%;
`;

const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9997;
    height: var(--footer-height);
    top: calc(100% - var(--footer-height));
    padding: 0 60px 0 ${(props) => (props.menuOpen ? 'var(--nav-menu-open-width);' : 'var(--nav-menu-closed-width);')};
    right: 0;
`;

const ImprintLink = styled.a`
    font-size: 12px;
    color: ${(props) => props.fontColor};
    &:link {
        text-decoration: none;
        color: ${(props) => props.fontColor};
    }
    &:visited {
        text-decoration: none;
        color: ${(props) => props.fontColor};
    }
    &:hover {
        text-decoration: none;
        color: ${(props) => props.fontColor};
    }
    &:active {
        text-decoration: none;
        color: ${(props) => props.fontColor};
    }
`;

const Layout = (props) => {
    let menuOpen = useStoreState((state) => state.ui.menuOpen);
    const theme = getTheme();
    /** Whether the CH features are enabled or not. */
    const enableChFeatures = useStoreState((state) => state.ui.enableChFeatures);

    return (
        <LayoutContainer>
            <TopMenu />
            <NavMenu />
            <ContentContainer menuOpen={menuOpen}>{props.children}</ContentContainer>
            <Footer open={menuOpen}>
                <ImprintLink fontColor={theme.palette.lightGrey} target="_blank" href={enableChFeatures ? I18n.get().t('Imprint_Link_Href_Ch') : generalOptions.imprint}>
                    {I18n.get().t('Imprint_Link')}
                </ImprintLink>
            </Footer>
        </LayoutContainer>
    );
};

export default Layout;
