import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';
import styled from 'styled-components/macro';
import { toCustomFormattedDateTime } from '../../../helper/DateFormatHelper';
import I18n from '../../../helper/Localization';
import { Colors } from '../../../styles/Globals';

const OfflineTestResultCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 185px;
    max-height: 185px;
    position: relative;
    border: 1px solid ${Colors.borderGray};
    border-radius: 4px;
    box-shadow: 1px 2px 6px ${Colors.boxShadow};
    padding: 15px 18px 18px 18px;
`;

const ProgressIndicator = styled.div`
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 18px;
    font-weight: bold;
`;

const NotesContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 15px 7px;
    font-size: 16px;
    font-weight: bold;
    overflow: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const CardHeadline = styled.div`
    font-size: 12px;
    font-weight: bold;
`;

const OfflineTestTimeWrapper = styled.div`
    font-size: 15px;
`;

/**
 * Component to display a single offline test result.
 */
export const OfflineTestResultCard = (props) => {
    /**
     * Props of the icon of the connect button.
     */
    const connectButtonIconProps = {
        iconName: 'PageLink',
    };

    /**
     * Styles of the delete button.
     */
    const deleteButtonStyles = {
        root: {
            border: 'unset',
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Styles of the connect button.
     */
    const connectButtonStyles = {
        root: {
            fontSize: '10px',
            marginLeft: '15px',
        },
    };

    /**
     * Callback to determine if the user is allowed to press delete or connect button.
     */
    const isButtonDisabled = () => {
        if (props.currentTestResultNumber > props.testResultsCount || props.testResultsCount === 0 || props.currentTestResultNumber === 0) {
            return true;
        }
        return false;
    };

    return (
        <OfflineTestResultCardContainer>
            <ProgressIndicator>{`${props.currentTestResultNumber} / ${props.testResultsCount}`}</ProgressIndicator>
            <CardHeadline>{I18n.get().t('OfflineTestResultCard_Headline')}</CardHeadline>
            <OfflineTestTimeWrapper>{props.offlineTestResult && toCustomFormattedDateTime(props.offlineTestResult.dateExecuted)}</OfflineTestTimeWrapper>
            <NotesContainer>{props.offlineTestResult && props.offlineTestResult.notes}</NotesContainer>
            <ButtonContainer>
                <DefaultButton disabled={isButtonDisabled()} styles={deleteButtonStyles} text={I18n.get().t('OfflineTestResultCard_Delete')} onClick={props.delete} />
                <PrimaryButton
                    disabled={isButtonDisabled() || props.isConnectDisabled}
                    styles={connectButtonStyles}
                    text={I18n.get().t('OfflineTestResultCard_Connect')}
                    iconProps={connectButtonIconProps}
                    onClick={props.connect}
                />
            </ButtonContainer>
        </OfflineTestResultCardContainer>
    );
};
