import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../styles/Globals';
import { IconButton, getTheme } from 'office-ui-fabric-react';
import DeviceTiltIcon from '../assets/device-tilt.svg';
import { PrimaryButton } from 'office-ui-fabric-react';
import I18n from '../helper/Localization';

const LandscapeTipOverlayContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(162, 162, 162, 0.9);
    z-index: 1000003;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 11px;
    padding: 25px;
    background-color: ${Colors.white};
    position: relative;
    width: 300px;
    max-width: 300px;
    height: 350px;
    max-height: 350px;
`;

const TextWrapper = styled.div`
    padding-top: 20px;
    font-size: 14px;
    text-align: center;
    word-wrap: break-word;
`;

/**
 * Overlay component that advises the user to rotate the device into landscape mode.
 */
export const LandscapeTipOverlay = () => {
    const theme = getTheme();

    /**
     * Whether the overlay is visible or not.
     */
    const [isVisible, setIsVisible] = useState(false);

    /**
     * Set the window size state after the window has resized.
     */
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < window.innerHeight && window.innerWidth < 1100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }
        handleResize();
        window.addEventListener('resize', () => handleResize());
        return () => window.removeEventListener('resize', () => handleResize());
    }, []);

    /**
     * Styles of the close button.
     */
    const closeButtonStyles = {
        root: {
            position: 'absolute',
            right: 5,
            top: 5,
            borderRadius: 4,
            color: theme.palette.black,
        },
    };

    /**
     * Styles of the confirm button.
     */
    const confirmButtonStyles = {
        root: {
            fontSize: 10,
            borderRadius: 4,
            minWidth: 110,
            width: 110,
            height: 24,
            marginTop: 45,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
        rootFocused: {
            outline: 0,
        },
    };

    /**
     * Callback to close the overlay.
     */
    const closeOverlay = () => setIsVisible(false);

    return (
        <>
            {isVisible ? (
                <LandscapeTipOverlayContainer>
                    <ContentContainer>
                        <IconButton styles={closeButtonStyles} iconProps={{ iconName: 'close-icon' }} onClick={closeOverlay} />
                        <div>
                            <img src={DeviceTiltIcon} height={120} alt="" />
                        </div>
                        <TextWrapper>{I18n.get().t('LandscapeTipOverlay_Text')}</TextWrapper>
                        <PrimaryButton styles={confirmButtonStyles} text={I18n.get().t('LandscapeTipOverlay_Confirm')} onClick={closeOverlay} />
                    </ContentContainer>
                </LandscapeTipOverlayContainer>
            ) : null}
        </>
    );
};
