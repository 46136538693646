import React, { useState, useCallback } from 'react';
import I18n from '../helper/Localization';
import styled from 'styled-components/macro';
import { IconButton, getTheme } from 'office-ui-fabric-react';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';

const Container = styled.div`
    outline: 0;
`;

/**
 * A basic icon button with an styled information icon that takes the callback function as prop
 * @param props
 */
export const InfoButton = (props) => {
    const theme = getTheme();

    const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);

    const toggleTeachingBubbleVisible = useCallback(
        (event) => {
            event.stopPropagation();
            setTeachingBubbleVisible(!teachingBubbleVisible);
        },
        [teachingBubbleVisible]
    );

    const buttonStyles = {
        icon: {
            marginBottom: '3px',
        },
        root: {
            height: '10px',
            width: '10px',
            marginLeft: '5px',
            backgroundColor: theme.palette.white,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
        rootFocused: {
            outline: 0,
        },
    };

    const closeButtonProps = React.useMemo(
        () => ({
            styles: {
                border: 'unset',
                outline: 0,
                rootHovered: {
                    backgroundColor: 'none',
                },
                rootPressed: {
                    backgroundColor: 'none',
                },
                rootFocused: {
                    outline: 0,
                },
            },
            children: I18n.get().t('InfoButton_Dialog_Close'),
            onClick: toggleTeachingBubbleVisible,
        }),
        [toggleTeachingBubbleVisible]
    );

    return (
        <Container>
            <IconButton
                id={props.id}
                styles={buttonStyles}
                autoFocus={false}
                iconProps={{ iconName: 'info-circle-solid-svg' }}
                onClick={(event) => toggleTeachingBubbleVisible(event)}
            />
            {teachingBubbleVisible && (
                <TeachingBubble target={'#' + props.id} primaryButtonProps={closeButtonProps} onDismiss={toggleTeachingBubbleVisible} headline={props.headlineText}>
                    {props.teachingBubbleContent}
                </TeachingBubble>
            )}
        </Container>
    );
};
