import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { msalApp } from '../helper/AuthHelper';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import I18n from '../helper/Localization';

/**
 * styled components
 */
const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Text = styled.p`
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 19px;
    font-size: 12px;
    font-weight: bold;
`;

/**
 * Avatar component
 */
const Avatar = () => {
    /**
     * The username
     */
    const [username, setUsername] = useState('');
    /**
     * avatar image
     */
    const [img] = useState(
        'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz4NCjxzdmcgd2lkdGg9IjQwMXB4IiBoZWlnaHQ9IjQwMXB4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDMxMi44MDkgMCA0MDEgNDAxIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjMxMi44MDkgMCA0MDEgNDAxIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KPGcgdHJhbnNmb3JtPSJtYXRyaXgoMS4yMjMgMCAwIDEuMjIzIC00NjcuNSAtODQzLjQ0KSI+DQoJPHJlY3QgeD0iNjAxLjQ1IiB5PSI2NTMuMDciIHdpZHRoPSI0MDEiIGhlaWdodD0iNDAxIiBmaWxsPSIjRTRFNkU3Ii8+DQoJPHBhdGggZD0ibTgwMi4zOCA5MDguMDhjLTg0LjUxNSAwLTE1My41MiA0OC4xODUtMTU3LjM4IDEwOC42MmgzMTQuNzljLTMuODctNjAuNDQtNzIuOS0xMDguNjItMTU3LjQxLTEwOC42MnoiIGZpbGw9IiNBRUI0QjciLz4NCgk8cGF0aCBkPSJtODgxLjM3IDgxOC44NmMwIDQ2Ljc0Ni0zNS4xMDYgODQuNjQxLTc4LjQxIDg0LjY0MXMtNzguNDEtMzcuODk1LTc4LjQxLTg0LjY0MSAzNS4xMDYtODQuNjQxIDc4LjQxLTg0LjY0MWM0My4zMSAwIDc4LjQxIDM3LjkgNzguNDEgODQuNjR6IiBmaWxsPSIjQUVCNEI3Ii8+DQo8L2c+DQo8L3N2Zz4NCg=='
    );
    /**
     * visibility state of the browser message
     */
    const [browserMessageHidden, setBrowserMessageHidden] = useState(true);

    /**
     * mimics componentDidMount
     */
    useEffect(() => {
        shouldBrowserMessageBeDisplayed();
        const fetchAvatar = async () => {
            const _msalApp = await msalApp();
            const name = _msalApp.getAccount().name;
            const userMail = _msalApp.getAccount().idToken.emails[0];
            localStorage.setItem('lastLoggedInUserMail', userMail);
            setUsername(name);
        };
        fetchAvatar();
    }, []);

    /**
     * Toggles the browser dialog
     * @param sender dummy argument to make this function work with the browser dialog onDismiss event
     * @param visible boolean value for the visible state
     */
    const toggleBrowserMessage = (sender, visible = null) => {
        if (visible == null) {
            setBrowserMessageHidden(!browserMessageHidden);
        } else {
            localStorage.setItem('BrowserMessageShown', 'true');
            setBrowserMessageHidden(visible);
        }
    };

    /**
     * Shows the browsermessage on the initial visit of the page. subsequent visits do not display the message.
     */
    const shouldBrowserMessageBeDisplayed = () => {
        if (localStorage.getItem('BrowserMessageShown') === 'true') {
            setBrowserMessageHidden(true);
        } else {
            setBrowserMessageHidden(false);
        }
    };


    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: I18n.get().t('BrowserMessage_Headline'),
        subText: I18n.get().t('BrowserMessage_Text'),
        showCloseButton: false,
    };

    const dialogStyles = {
        root: {
            zIndex: 1000002,
        },
    };

    const dialogButtonStyles = {
        root: {
            selectors: {
                ':focus': {
                    outline: 'unset',
                },
            },
        },
    };

    return (
        <AvatarContainer>
            {username && <Text>{`${I18n.get().t('User_Greeting')}, ${username}`}</Text>}
            <Persona id="avatar" imageUrl={img} size={PersonaSize.size24} hidePersonaDetails={false} imageAlt="User" />
            <Dialog
                styles={dialogStyles}
                minWidth={700}
                hidden={browserMessageHidden}
                onDismiss={(e) => {
                    toggleBrowserMessage(e, true);
                }}
                dialogContentProps={dialogContentProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        styles={dialogButtonStyles}
                        onClick={(e) => {
                            toggleBrowserMessage(e, true);
                        }}
                        text={I18n.get().t('BrowserMessage_Accept')}
                    />
                </DialogFooter>
            </Dialog>
        </AvatarContainer>
    );
};

export default Avatar;
