/**
 * plays a sound
 * @param side 'left' ear or 'right' ear, default=both ears
 * @param gain the volume of the tone, between 0 and 1
 * @param frequency the frequency of the tone
 * @param duration the duration of the tone in seconds
 * @param offsetDuration an offset in seconds before the tone is played.
 * @param callback the callback function to execute after sound is played
 */
export const playSound = (side, gain, frequency, duration, offsetDuration, callback) => {
  // Default, Safari and older versions of Chrome
  const AudioContext = window.AudioContext || window.webkitAudioContext || false;
  if (!AudioContext) {
    console.error('No audio context available');
    alert('Your device does not support an audio output.');
    return;
  }
  const audioContext = new AudioContext();
  // The volume can not reach an absolute zero, therefore the nearest approximation.
  const ZERO = 0.000001;
  // Fading duration in seconds before and after each sound.
  const fadeDuration = 0.1;
  const oscillator = audioContext.createOscillator();
  const gainNodeL = audioContext.createGain();
  const gainNodeR = audioContext.createGain();
  const merger = audioContext.createChannelMerger(2);
  // connect left and right node to the oscillator
  oscillator.connect(gainNodeL);
  oscillator.connect(gainNodeR);
  // connect channel merger to the left and right node
  gainNodeL.connect(merger, 0, 0);
  gainNodeR.connect(merger, 0, 1);
  // connect the audio destination to the channel merger
  merger.connect(audioContext.destination);
  // Offset of silence
  gainNodeR.gain.setValueAtTime(ZERO, audioContext.currentTime);
  gainNodeL.gain.setValueAtTime(ZERO, audioContext.currentTime);
  // determine on which ear the sound will be played using the given gain
  switch (side) {
    case 'left':
      // play sound on left ear
      gainNodeR.gain.setValueAtTime(ZERO, audioContext.currentTime + offsetDuration);
      gainNodeL.gain.setValueAtTime(ZERO, audioContext.currentTime + offsetDuration);
      gainNodeL.gain.exponentialRampToValueAtTime(gain, audioContext.currentTime + fadeDuration + offsetDuration);
      gainNodeL.gain.setValueAtTime(gain, audioContext.currentTime + duration + offsetDuration);
      gainNodeL.gain.exponentialRampToValueAtTime(ZERO, audioContext.currentTime + duration + fadeDuration + offsetDuration);
      break;
    case 'right':
      // play sound on right ear
      gainNodeL.gain.setValueAtTime(ZERO, audioContext.currentTime + offsetDuration);
      gainNodeR.gain.setValueAtTime(ZERO, audioContext.currentTime + offsetDuration);
      gainNodeR.gain.exponentialRampToValueAtTime(gain, audioContext.currentTime + fadeDuration + offsetDuration);
      gainNodeR.gain.setValueAtTime(gain, audioContext.currentTime + duration + offsetDuration);
      gainNodeR.gain.exponentialRampToValueAtTime(ZERO, audioContext.currentTime + duration + fadeDuration + offsetDuration);
      break;
    default:
      // play sound on both ears
      gainNodeL.gain.setValueAtTime(ZERO, audioContext.currentTime + offsetDuration);
      gainNodeL.gain.exponentialRampToValueAtTime(gain, audioContext.currentTime + fadeDuration + offsetDuration);
      gainNodeL.gain.setValueAtTime(gain, audioContext.currentTime + duration + offsetDuration);
      gainNodeL.gain.exponentialRampToValueAtTime(ZERO, audioContext.currentTime + duration + fadeDuration + offsetDuration);
      gainNodeR.gain.setValueAtTime(ZERO, audioContext.currentTime + offsetDuration);
      gainNodeR.gain.exponentialRampToValueAtTime(gain, audioContext.currentTime + fadeDuration + offsetDuration);
      gainNodeR.gain.setValueAtTime(gain, audioContext.currentTime + duration + offsetDuration);
      gainNodeR.gain.exponentialRampToValueAtTime(ZERO, audioContext.currentTime + duration + fadeDuration + offsetDuration);
      break;
  }
  // set the sound type to sine
  oscillator.type = 'sine';
  // set the frequency
  oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime + offsetDuration);
  // start playing the sound
  oscillator.start();
  if (audioContext.state === 'suspended') {
    // Mobile and Safari suspend the audio context if it is not playing.
    audioContext.resume();
  }
  // stop the sound after given amount of seconds
  setTimeout(() => {
    oscillator.stop();
    audioContext.close();
    callback();
  }, (duration + 2 * fadeDuration + offsetDuration) * 1000);
};
