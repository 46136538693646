import React from 'react';
import styled from 'styled-components/macro';
import { Colors } from '../../../styles/Globals';
import { IconButton } from 'office-ui-fabric-react';

const TagContainer = styled.div`
    display: flex;
    align-items: center;
    width: max-content;
    position: relative;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 4px;
    margin: 5px;
`;

const TagTextWrapper = styled.div`
    font-size: 12px;
    color: ${Colors.white};
    padding: 3px 10px;
`;

/**
 * Tag to display a single end customer number.
 */
export const EndCustomerNumberTag = (props) => {
    /**
     * Styles of the delete button.
     */
    const deleteButtonStyles = {
        root: {
            minHeight: 20,
            maxHeight: 20,
            minWidth: 20,
            maxWidth: 20,
            marginRight: 6,
        },
        icon: {
            fontSize: 10,
            color: Colors.white,
        },
        rootHovered: {
            backgroundColor: 'none',
            outline: 0,
        },
        rootPressed: {
            backgroundColor: 'none',
            outline: 0,
        },
        rootFocused: {
            backgroundColor: 'none',
            outline: 0,
        },
    };

    /**
     * Props of the delete icon.
     */
    const deleteIconProps = {
        iconName: 'ChromeClose',
    };

    return (
        <TagContainer backgroundColor={props.backgroundColor}>
            <TagTextWrapper>{props.endCustomerNumber}</TagTextWrapper>
            <IconButton styles={deleteButtonStyles} iconProps={deleteIconProps} onClick={props.callback} />
        </TagContainer>
    );
};
