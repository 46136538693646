import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { TestProgressBar } from './TestProgressBar';
import { Colors } from '../../styles/Globals';
import { BusySpinnerOverlay } from '../busyIndicators/BusySpinnerOverlay';

const LayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: ${Colors.lightGray};
    position: relative;
    overflow: auto;
`;

const ContainerElement = styled.div`
    display: flex;
    flex: 1;
    min-height: max-content;
    max-height: calc(100% - 171px);
    @media only screen and (max-width: 1400px) {
        max-height: calc(100% - 151px);
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    margin: 20px;
    background-color: ${Colors.white};
    box-shadow: 14px 17px 54px -15px rgba(0, 0, 0, 0.3);
`;

/**
 * layout for the hearing test without navigation menu
 */
export default class TestLayout extends Component {
    render() {
        return (
            <LayoutContainer>
                <BusySpinnerOverlay isBusy={this.props.isBusy} />
                <TestProgressBar progress={this.props.progress} />
                <ContainerElement>
                    <ContentContainer>{this.props.children}</ContentContainer>
                </ContainerElement>
            </LayoutContainer>
        );
    }
}
