import styled from 'styled-components/macro';

/**
 * style element which applies a monospace font used in some table cells for better readability.
 */
export const Mono = styled.div`
    font-family: 'Droid Sans Mono';
`;

/**
 * Global accessable colors.
 */
export const Colors = {
    white: '#ffffff',
    lightGray: '#f7f7f7',
    dialogGray: '#A3A3A3',
    inactiveGray: '#999999',
    altGray: '#6C6C6C',
    boxShadow: '#00000077',
    hoverLightGray: '#D3D3D3',
    borderGray: '#dbdbdb',
    errorRed: '#a4262c',
    errorRedLight: '#FDE7E9',
    overlayGray: '#a2a2a2',
    backgroundGray: '#f1f1f1',
    darkGreen: '#215252',
};
