import React from 'react';
import styled from 'styled-components/macro';
import I18n from '../../helper/Localization';
import { PrimaryButton, getTheme } from 'office-ui-fabric-react';
import { useStoreState } from 'easy-peasy';
import { Colors } from '../../styles/Globals';

const ResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const HeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    padding: 55px 0 0 51px;
    color: ${(props) => props.fontColor};
    @media only screen and (max-width: 1400px) {
        font-size: 23px;
        padding: 15px;
    }
`;

const SubHeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 40px;
    font-weight: bold;
    padding-left: 51px;
    color: ${(props) => props.fontColor};
    @media only screen and (max-width: 1400px) {
        font-size: 23px;
        padding-left: 15px;
    }
`;

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin-top: 40px;
    max-height: calc(100vh - 540px);
    @media only screen and (max-width: 1400px) {
        max-height: calc(100vh - 440px);
    }
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 50px 50px 50px;
    min-height: max-content;
    @media only screen and (max-width: 1400px) {
        padding: 15px;
    }
`;

const BottomTextWrapper = styled.div`
    margin-right: 20px;
`;

const List = styled.ul`
    min-height: max-content;
`;

const ListItem = styled.li`
    font-size: 16px;
    margin: 15px 51px;
`;

const confirmButtonStyle = {
    root: {
        borderRadius: '5px',
        fontSize: '15px',
        border: `1px solid ${Colors.white}`,
        minWidth: 'max-content',
        width: '200px',
        height: '39px',
        marginLeft: 'auto',
    },
    rootHovered: {
        backgroundColor: 'none',
        color: 'none',
        border: '1px solid #ffffff',
    },
    rootPressed: {
        backgroundColor: 'none',
        color: 'none',
        border: '1px solid #ffffff',
    },
};

/**
 * view component that contains the success result of the hearing test
 */
export const TestSuccessView = (props) => {
    const theme = getTheme();

    /**
     * Retrieve the store state of the offline mode.
     */
    const isOfflineModeEnabled = useStoreState((state) => state.ui.isOfflineModeEnabled);

    return (
        <ResultContainer>
            <HeadlineContainer fontColor={theme.palette.green}>{I18n.get().t('Test_Result_HeadlineSuccess')}</HeadlineContainer>
            <SubHeadlineContainer fontColor={theme.palette.black}>{I18n.get().t('Test_Result_SubHeadlineSuccess')}</SubHeadlineContainer>
            <ListContainer>
                <List>
                    <ListItem>{I18n.get().t('Test_Result_One')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Two')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Three')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Four')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Five')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Six')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Seven')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Eight')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Nine')}</ListItem>
                    <ListItem>{I18n.get().t('Test_Result_Ten')}</ListItem>
                </List>
            </ListContainer>
            <BottomContainer>
                <BottomTextWrapper>{I18n.get().t('Test_Result_PleaseConfirmText')}</BottomTextWrapper>
                <PrimaryButton
                    styles={confirmButtonStyle}
                    text={isOfflineModeEnabled ? I18n.get().t('OfflineMode_TestFinish_Button') : I18n.get().t('Test_Result_Confirm')}
                    onClick={props.abort}
                />
            </BottomContainer>
        </ResultContainer>
    );
};
