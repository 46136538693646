import React from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';

const success = mergeStyles({
    backgroundColor: '#12B361',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 0,
    borderRadius: 2,
    boxShadow: '1px 2px 3px #0000003C',
    fontSize: 18,
    color: 'white',
    height: 28,
    width: 28,
    paddingLeft: 3,
});

const error = mergeStyles({
    backgroundColor: '#EA1E1E',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 0,
    borderRadius: 2,
    boxShadow: '1px 2px 3px #0000003C',
    fontSize: 18,
    color: 'white',
    height: 28,
    width: 28,
    paddingLeft: 3,
});

const pending = mergeStyles({
    backgroundColor: '#666666',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 0,
    borderRadius: 2,
    boxShadow: '1px 2px 3px #0000003C',
    fontSize: 18,
    color: 'white',
    height: 28,
    width: 28,
    paddingLeft: 3,
});

const returning = mergeStyles({
    backgroundColor: '#00349A',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 0,
    borderRadius: 2,
    boxShadow: '1px 2px 3px #0000003C',
    fontSize: 18,
    color: 'white',
    height: 28,
    width: 28,
    paddingLeft: 3,
});

/**
 * Enum to represent the status.
 */
export const Status = {
    Returning: 'returning',
    Success: 'success',
    Error: 'error',
    Pending: 'pending',
};

/**
 * The value which represents the age at which the success status switches to returning.
 */
export const StatusReturningAge = 94694400000;

/**
 * helper method that returns the correct status icon
 * @param param
 */
const renderSwitch = (param) => {
    switch (param) {
        case Status.Returning:
            return <FontIcon iconName="StatusCircleSync" className={returning} />;
        case Status.Success:
            return <FontIcon iconName="StatusCircleCheckmark" className={success} />;
        case Status.Error:
            return <FontIcon iconName="StatusCircleErrorX" className={error} />;
        default:
            return <FontIcon iconName="StatusCircleQuestionMark" className={pending} />;
    }
};

/**
 * a basic status icon that takes the status as prop
 * @param status {successState} The success state.
 */
export const StatusIcon = ({ status }) => {
    return renderSwitch(status);
};
