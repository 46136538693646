import React from 'react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import I18n from '../helper/Localization';
import { Icon } from '@fluentui/react/lib/Icon';
import { getTheme } from 'office-ui-fabric-react';
import { Colors } from '../styles/Globals';

/**
 * Component to choose from available languages.
 */
export const LanguageSelector = () => {
    const theme = getTheme();

    /** The styled language icon. */
    const languageIcon = <Icon styles={{ root: { marginRight: 10, color: Colors.darkGreen, '&:hover': { color: theme.palette.themePrimary }, marginTop: 1 } }} iconName="Globe" />;

    /** Render func for the dropdown placeholder. */
    const onRenderPlaceholder = (props) => (
        <div>
            {languageIcon}
            <span>{props.placeholder}</span>
        </div>
    );

    /** Render func for the title. */
    const onRenderTitle = (options) => {
        const option = options[0];
        return (
            <>
                {languageIcon}
                <div style={{ 'margin-top': '-31px', 'margin-left': '20px' }}>
                    <span>{option.text}</span>
                </div>
            </>
        );
    };

    /** The available language options. */
    const dropdownOptions = [
        { key: 'en', text: I18n.get().t('LanguageSelector_Option_English'), data: { icon: 'Globe' } },
        { key: 'de', text: I18n.get().t('LanguageSelector_Option_German'), data: { icon: 'Globe' } },
        { key: 'fr', text: I18n.get().t('LanguageSelector_Option_French'), data: { icon: 'Globe' } },
    ];

    /** Styles for the language selection dropdown. */
    const dropdownStyles = {
        root: {
            minWidth: 120,
        },
        title: {
            border: 'none',
            ':focus::after': {
                border: 'none',
            },
            fontSize: 12,
            label: {},
        },
        dropdown: {
            ':focus::after': {
                border: 'none',
            },
        },
    };

    return (
        <Dropdown
            selectedKey={I18n.get().language}
            styles={dropdownStyles}
            options={dropdownOptions}
            onRenderPlaceholder={onRenderPlaceholder}
            onRenderTitle={onRenderTitle}
            placeholder={I18n.get().t('LanguageSelector_Dropdown_Placeholder')}
            onChange={(_, newValue) => I18n.changeLanguage(newValue.key)}
        />
    );
};
