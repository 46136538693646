import React, { useState, useEffect, useRef, useMemo } from 'react';
import I18n from '../../helper/Localization';
import styled from 'styled-components/macro';
import { TableView } from './TableView';
import { IconButton, getTheme } from 'office-ui-fabric-react';
import { InfoButton } from '../InfoButton';
import { StatusIcon, Status } from '../../components/StatusIcon';
import { isDateReturning } from '../../helper/DateReturningHelper';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { postToApi, postToApiBlob } from '../../helper/ApiHelper';
import { booleanSortType } from '../../helper/SortHelper';
import { TestStatusTeachingBubbleContent } from '../TestStatusTeachingBubbleContent';
import { toFormattedDate } from '../../helper/DateFormatHelper';
import { Mono } from '../../styles/Globals';

const InfoWrapper = styled.div`
    display: flex;
    width: 100%;
    cursor: pointer;
`;

const SortableTableHeader = styled.div`
    cursor: pointer;
`;

/**
 * component to display the results view
 */
export const ResultsView = (props) => {
    const theme = getTheme();

    /**
     * The test results to display in the table.
     */
    const [testResults, setTestResults] = useState([]);

    /**
     * visible state for the busy indicator
     */
    const [isBusy, setIsBusy] = useState(false);

    /**
     * Whether the table search is busy or not.
     */
    const [isSearchBusy, setIsSearchBusy] = useState(false);

    /**
     * Whether the pdf download is busy or not.
     */
    const [isDownloadBusy, setIsDownloadBusy] = useState(false);

    /**
     * The maximum number of pages of the current fetched data.
     */
    const [maxPages, setMaxPages] = useState(1);

    /**
     * The current selected data page.
     */
    const [currentPage, setCurrentPage] = useState(1);

    /**
     * State of the current sort direction. true for ASC, false for DESC.
     */
    const [sortAscending, setSortAscending] = useState(false);

    /**
     * State of the current sort property.
     */
    const [sortPropertyName, setSortPropertyName] = useState('DateExecuted');

    /**
     * State of the current sort property.
     */
    const [searchValue, setSearchValue] = useState('');

    /**
     * Whether the user has sorted the table or not.
     */
    const hasSorted = useRef(false);

    const previousSearchValue = useRef('');

    /**
     * fluent ui
     */
    const buttonStyles = {
        icon: {
            fontSize: 12,
            marginBottom: '4px',
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 28,
            height: 28,
        },
        rootHovered: {
            backgroundColor: 'none',
        },
        rootPressed: {
            backgroundColor: 'none',
        },
    };

    /** Request abort controller. */
    const abortController = useMemo(() => new AbortController(), []);

    /**
     * Component will unmount, so abort.
     */
    useEffect(() => {
        return () => {
            abortController.abort();
        };
    }, [abortController]);

    useEffect(() => {
        const fetchData = async () => {
            setIsBusy(true);
            try {
                const defaultReqBody = {
                    itemsPerPage: 50,
                    sortDirection: sortAscending ? 1 : 2,
                    sortPropertyName: sortPropertyName,
                };
                let reqBody;
                if (searchValue && searchValue !== '') {
                    setIsSearchBusy(true);
                    if (searchValue !== previousSearchValue.current) {
                        reqBody = {
                            filterText: searchValue.toLowerCase(),
                            pageToDeliver: 1,
                            ...defaultReqBody,
                        };
                    } else {
                        reqBody = {
                            filterText: searchValue.toLowerCase(),
                            pageToDeliver: currentPage,
                            ...defaultReqBody,
                        };
                    }
                } else {
                    if (previousSearchValue.current !== '') {
                        setIsSearchBusy(true);
                    }
                    reqBody = {
                        pageToDeliver: currentPage,
                        ...defaultReqBody,
                    };
                }
                const testResults = await postToApi('v1.0/TestResult/Paged', reqBody, abortController.signal);
                setMaxPages(testResults.pagesAvailable);
                const testResultViewModels = testResults.results.map((testResult) => {
                    return {
                        deliveryInvoiceNumber: testResult.orderPosition.order.deliveryInvoiceNumber,
                        position: testResult.orderPosition.position,
                        wearer: testResult.orderPosition.wearer,
                        isSuccessful: testResult.isSuccessful,
                        insulationLeft: testResult.insulationLeft,
                        insulationRight: testResult.insulationRight,
                        dateExecuted: testResult.dateExecuted,
                        id: testResult.id,
                    };
                });
                setTestResults(testResultViewModels);
            } catch (error) {
                setMaxPages(1);
                setCurrentPage(1);
                setTestResults([]);
            } finally {
                previousSearchValue.current = searchValue;
                setIsBusy(false);
                setIsSearchBusy(false);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, setCurrentPage, searchValue, setSearchValue, sortAscending, setSortAscending, sortPropertyName, setSortPropertyName]);

    /**
     * Sorts the table by the given property name considering the sort direction state.
     */
    const handlePropertySort = (propertyName) => {
        if (sortPropertyName === propertyName) {
            setSortAscending(!sortAscending);
        } else {
            setSortAscending(true);
            setSortPropertyName(propertyName);
        }
    };

    /**
     * callback function for the download of one test result as pdf file.
     */
    const downloadTestResultAsPdf = async (testResult) => {
        setIsDownloadBusy(true);
        const date = toFormattedDate('de-DE', testResult.dateExecuted);
        const fileName = `${I18n.get().t('PDFExport_FileName')}_${date}.pdf`;
        await postToApiBlob(
            `v1.0/Documents/result-document/${I18n.getInstance().i18nInstance?.language ? I18n.getInstance().i18nInstance.language : 'de'}/${testResult.id}`,
            null,
            fileName,
            abortController.signal
        );
        setIsDownloadBusy(false);
    };

    const columns = [
        {
            Header: '',
            accessor: 'interactions',
            width: 75,
            maxWidth: -1,
            minWidth: 75,
            Cell: (table) => (
                <IconButton
                    styles={buttonStyles}
                    iconProps={{ iconName: 'download-solid-white-svg' }}
                    title="Download"
                    ariaLabel="Download"
                    onClick={() => downloadTestResultAsPdf(table.row.original)}
                />
            ),
        },
        {
            Header: (
                <SortableTableHeader onClick={() => handlePropertySort('OrderPosition.Wearer')}>
                    {I18n.get().t('Table_Header_Supporter')}
                    {sortPropertyName === 'OrderPosition.Wearer' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'wearer',
            width: 110,
        },
        {
            Header: (
                <SortableTableHeader onClick={() => handlePropertySort('OrderPosition.Order.DeliveryInvoiceNumber')}>
                    {I18n.get().t('Table_Header_Order')}
                    {sortPropertyName === 'OrderPosition.Order.DeliveryInvoiceNumber' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'deliveryInvoiceNumber',
            width: 75,
            Cell: (table) => <Mono>{table.cell.value}</Mono>,
        },
        {
            Header: (
                <SortableTableHeader onClick={() => handlePropertySort('OrderPosition.Position')}>
                    {I18n.get().t('Table_Header_Position')}
                    {sortPropertyName === 'OrderPosition.Position' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'position',
            width: 75,
            Cell: (table) => <Mono>{table.cell.value}</Mono>,
        },
        {
            Header: (
                <InfoWrapper onClick={() => handlePropertySort('IsSuccessful')}>
                    {I18n.get().t('Table_Header_Status')}
                    {sortPropertyName === 'IsSuccessful' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                    <InfoButton id="test_status_resultsview" headlineText={I18n.get().t('InfoButton_Dialog_Headline')} teachingBubbleContent={TestStatusTeachingBubbleContent()} />
                </InfoWrapper>
            ),
            accessor: 'isSuccessful',
            Cell: (table) => (
                <StatusIcon
                    status={
                        isDateReturning(table.row.original.dateExecuted) && table.cell.value
                            ? Status.Returning
                            : table.cell.value === true
                            ? Status.Success
                            : table.cell.value === false
                            ? Status.Error
                            : Status.Pending
                    }
                />
            ),
            width: 70,
            sortType: booleanSortType,
        },
        {
            Header: I18n.get().t('Table_Header_InsulationValue'),
            accessor: 'insulationLeft',
            width: 115,
            Cell: (table) => `${table.row.original.insulationLeft} dB / ${table.row.original.insulationRight} dB`,
        },
        {
            Header: (
                <SortableTableHeader
                    onClick={() => {
                        hasSorted.current = true;
                        handlePropertySort('DateExecuted');
                    }}
                >
                    {I18n.get().t('Table_Header_Execution')}
                    {hasSorted.current !== false && sortPropertyName === 'DateExecuted' && <FontIcon iconName={sortAscending ? 'SortUp' : 'SortDown'} />}
                </SortableTableHeader>
            ),
            accessor: 'dateExecuted',
            width: 115,
            Cell: (table) => toFormattedDate('de-DE', table.cell.value),
        },
    ];

    return (
        <TableView
            isBusy={isBusy}
            isBusyAlt={isDownloadBusy}
            data={testResults}
            columns={columns}
            viewName={I18n.get().t('ViewName_Results')}
            viewKey="results"
            maxPages={maxPages}
            currentPage={currentPage}
            setCurrentPage={(page) => setCurrentPage(page)}
            setSearchValue={(searchValue) => setSearchValue(searchValue)}
            isSearchBusy={isSearchBusy}
        />
    );
};
