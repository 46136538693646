import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { getTheme } from 'office-ui-fabric-react';

const CardContainer = styled.div`
    display: flex;
    border-radius: 5px;
    flex-direction: column;
    min-width: 282px;
    width: 25%;
    max-height: 300px;
    background-color: ${(props) => props.backgroundColor};
    box-shadow: 1px 2px 6px ${(props) => props.shadowColor};
`;

const HeadlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 10px 0 0 10px;
    font-size: 30px;
    @media only screen and (max-width: 1400px) {
        font-size: 18px;
    }
    color: ${(props) => props.fontColor};
`;

const InstructionContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 22px 15px 22px;
    font-size: 35px;
    color: ${(props) => props.fontColor};
    font-weight: bold;
    @media only screen and (max-width: 1400px) {
        font-size: 23px;
    }
`;

/**
 * card component that contains the instruction number and the instruction text
 */
export class TestInstructionCard extends Component {
    theme = getTheme();
    render() {
        return (
            <CardContainer backgroundColor={this.theme.palette.neutralDark} shadowColor={this.theme.palette.black}>
                <HeadlineContainer fontColor={this.theme.palette.lightGrey}>{this.props.number}</HeadlineContainer>
                <InstructionContainer fontColor={this.theme.palette.white}>{this.props.text}</InstructionContainer>
            </CardContainer>
        );
    }
}
