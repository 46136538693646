import React from 'react';
import I18n from '../../../helper/Localization';
import styled from 'styled-components/macro';
import { IconButton, getTheme } from 'office-ui-fabric-react';
import Table from '../../Table';

/**
 *  styled components
 */
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    flex: 1;

    flex-direction: column;
`;

const DebtorTable = styled.div`
    height: 230px;
    margin-right: 20px;
`;

const Text = styled.p`
    color: var(--unnamed-color-000000);
    text-align: left;
    margin: 0px;
    font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
`;

/**
 * Debtor list component
 */
const DebtorList = (props) => {
    /**
     * fluent ui theme
     */
    const theme = getTheme();
    /**
     * fluent ui
     */
    const buttonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 22,
            height: 22,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },

        rootPressed: {
            backgroundColor: 'none',
        },
    };

    const columns = [
        {
            Header: I18n.get().t('Debtor_List_ColumnHeader_Number'),
            accessor: 'identifier',
            width: 70,
        },
        {
            Header: I18n.get().t('Debtor_List_ColumnHeader_CompanyName'),
            accessor: 'companyName',
            width: 120,
        },
        {
            Header: I18n.get().t('Debtor_List_ColumnHeader_Address'),
            accessor: 'address',
            width: 120,
        },
        {
            Header: I18n.get().t('Debtor_List_ColumnHeader_Actions'),
            accessor: 'interactions',
            width: 20,
            Cell: (table) => {
                const debtor = table.row.original;
                return (
                    <ActionWrapper>
                        <IconButton
                            styles={buttonStyles}
                            iconProps={{ iconName: 'ChromeClose' }}
                            title={I18n.get().t('Debtor_List_Column_Actions_Remove')}
                            ariaLabel={I18n.get().t('Debtor_List_Column_Actions_Remove')}
                            onClick={(target) => props.deselectDebtor(debtor)}
                        />
                    </ActionWrapper>
                );
            },
        },
    ];

    return (
        <ContentContainer>
            <Text>{I18n.get().t('Debtor_List_Title')}</Text>
            <DebtorTable>
                <Table data={props.assignedDebtors} columns={columns} />
            </DebtorTable>
        </ContentContainer>
    );
};

export default DebtorList;
