import React from 'react';
import I18n from '../../../helper/Localization';
import styled from 'styled-components/macro';
import { StatusIcon, Status } from '../../../components/StatusIcon';
import { isDateReturning } from '../../../helper/DateReturningHelper';
import { InfoButton } from '../../InfoButton';
import { postToApiBlob } from '../../../helper/ApiHelper';
import { IconButton, getTheme } from 'office-ui-fabric-react';
import { toFormattedDate } from '../../../helper/DateFormatHelper';
import Table from '../../Table';
import { TestStatusTeachingBubbleContent } from '../../TestStatusTeachingBubbleContent';

/**
 *  styled components
 */
const OrderTestListContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

const TestTable = styled.div`
    display: flex;
    flex: 1;
    max-height: calc(100% - 15px);
`;

const Text = styled.p`
    text-align: left;
    margin: 0px;
    font: Bold 12px/15px NHaasGroteskTXW01-75Bd;
    letter-spacing: 0px;
    opacity: 1;
`;

const ColumnHeader = styled.div`
    display: flex;
    flex-direction: row;
`;

/**
 * Wrapper component for a table
 */
const OrderTestList = (props) => {
    /** Request abort controller. */
    const abortController = new AbortController();

    /**
     * callback function for the download of one test result as pdf file.
     */
    const downloadTestResultAsPdf = async (testResult) => {
        if (props.setBusy) {
            props.setBusy(true);
        }
        const fileName = `${I18n.get().t('PDFExport_FileName')}_${testResult.dateExecuted}.pdf`;
        await postToApiBlob(
            `v1.0/Documents/result-document/${I18n.getInstance().i18nInstance?.language ? I18n.getInstance().i18nInstance.language : 'de'}/${testResult.id}`,
            null,
            fileName,
            abortController.signal
        );
        if (props.setBusy) {
            props.setBusy(false);
        }
    };

    /**
     * fluent ui theme
     */
    const theme = getTheme();

    /**
     * fluent ui
     */
    const buttonStyles = {
        icon: {
            color: 'white',
            fontSize: 10,
        },
        root: {
            border: 0,
            borderRadius: '2px',
            boxShadow: '1px 2px 3px #0000003C',
            backgroundColor: theme.palette.themePrimary,
            width: 28,
            height: 28,
            marginRight: 10,
        },
        rootHovered: {
            backgroundColor: 'none',
        },

        rootPressed: {
            backgroundColor: 'none',
        },
    };

    /**
     * Column definition for the table
     */
    const columns = [
        {
            Header: (
                <ColumnHeader>
                    {I18n.get().t('OrderDetail_Table_StatusHeader')}
                    <InfoButton id="test_status_orderdetail" headlineText={I18n.get().t('InfoButton_Dialog_Headline')} teachingBubbleContent={TestStatusTeachingBubbleContent()} />
                </ColumnHeader>
            ),
            accessor: 'isSuccessful',
            Cell: (table) => (
                <StatusIcon
                    status={
                        isDateReturning(table.row.original.dateExecuted) && table.cell.value
                            ? Status.Returning
                            : table.cell.value === true
                            ? Status.Success
                            : table.cell.value === false
                            ? Status.Error
                            : Status.Pending
                    }
                />
            ),
            width: 80,
        },
        {
            Header: I18n.get().t('OrderDetail_Table_InsulationHeader'),
            accessor: 'insulation',
            width: 120,
        },
        {
            Header: I18n.get().t('OrderDetail_Table_ExecutionHeader'),
            accessor: 'dateExecuted',
            width: 120,
            Cell: (table) => toFormattedDate('de-DE', table.cell.value),
        },
        {
            Header: I18n.get().t('OrderDetail_Table_ActionsHeader'),
            accessor: 'actions',
            width: 20,
            Cell: (table) => {
                return (
                    <ActionWrapper>
                        <IconButton
                            styles={buttonStyles}
                            iconProps={{
                                iconName: 'download-solid-white-svg',
                                style: {
                                    color: theme.palette.neutralPrimary,
                                },
                            }}
                            title="Export"
                            ariaLabel="Export"
                            onClick={() => downloadTestResultAsPdf(table.row.original)}
                        />
                    </ActionWrapper>
                );
            },
        },
    ];

    return (
        <OrderTestListContainer>
            <Text>{I18n.get().t('Order_Detail_Tests_Title')}</Text>
            <TestTable>
                <Table
                    data={
                        props.testResults && props.testResults.length > 0
                            ? props.testResults.map((testResult) => {
                                  return {
                                      ...testResult,
                                      insulation: `${testResult.insulationLeft} dB / ${testResult.insulationRight} dB`,
                                  };
                              })
                            : []
                    }
                    columns={columns}
                />
            </TestTable>
        </OrderTestListContainer>
    );
};

export default OrderTestList;
